import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

import { ReturnMessage, UpdateStatusBook } from 'src/app/models/add-book.model';
import { DialogDataBookHistory } from 'src/app/models/dialog.model';

import { AddBookService } from 'src/app/services/add-book.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: ['./cancel-booking.component.scss']
})
export class CancelBookingComponent implements OnInit {
  cancelNotes: string;
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto');
  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataBookHistory,
  public dialogRef: MatDialogRef <CancelBookingComponent>,
  private addBookService: AddBookService,
  private formBuilder: FormBuilder,
  private uiService: UiService,
  private spinner: NgxSpinnerService,
  @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      cancelNotes: [null, Validators.required]
    });
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  getNotesError() {
    return this.form.get('cancelNotes').hasError('required') ? 'You must enter notes' : '';
  }

  async onCancel() {
    let isValidated = true;
    if (this.form.invalid) {
      isValidated = false;
    }

    if (!isValidated) {
      this.form.markAllAsTouched();
      const el = this.document.getElementById('cancel-form');
      el.scrollIntoView({ behavior: 'smooth' });
      return;
    } else {
      const confirm = await this.uiService.showConfirm('confirm-cancel', 'Cancel this booking?',
        "A cancellation email will be automatically sent to customer's email", true);

      if (confirm) {
        this.spinner.show();
        const cancelBookData: UpdateStatusBook = {
          bookNum: this.data.bookHistory.bookNum,
          statusID: 12,
          reason: this.form.get('cancelNotes').value
        };

        this.addBookService.updateStatusBook(cancelBookData).subscribe(
          (data: ReturnMessage) => {
            if (data.success) {
              this.spinner.hide();
              this.dialogRef.close(true);
            } else {
              this.uiService.showAlert(null, data.message);
              this.spinner.hide();
            }
          },
          (error: HttpErrorResponse) => {
            let errMsg = 'Failed connect to server. Please Try Again.';
            if (error.error.message) {
              errMsg = error.error.message;
            }
            this.uiService.showAlert(null, errMsg);
            this.spinner.hide();
          }
        );
      }
    }
  }

}
