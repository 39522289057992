import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

import { GridPaginationComponent } from '../grid-pagination/grid-pagination.component';

@Component({
  selector: 'app-grid-pager',
  templateUrl: './grid-pager.component.html',
  styleUrls: ['./grid-pager.component.scss']
})
export class GridPagerComponent implements OnInit, OnChanges {
  @Input() for: GridPaginationComponent;
  @Input() display = 'vertical';
  @Input() alreadySelectedCategoryDetail: number;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.alreadySelectedCategoryDetail) {
      if (changes.alreadySelectedCategoryDetail.currentValue > changes.alreadySelectedCategoryDetail.previousValue) {
        this.for.goFirstPage();
      }
    }
  }

  ngOnInit() {
  }

}
