<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" 
    size = "large" 
    color = "#f07600" 
    type = "ball-atom" 
    [fullScreen] = "true"><p style="color: white" > Loading... </p>
</ngx-spinner>
<div *ngIf="!(fetchBranchData$ | async)" 
    class="d-flex flex-column h-100 justify-content-center align-items-center loading-overlay">
    <img src="assets/images/top-right-acc.svg" class="login-right-item" />
    <img src="assets/images/esb-logo.gif" class="logo" />
    <div class="d-flex justify-content-center loading">
        <div class="d-flex flex-column justify-content-center">
            <p>Please wait</p>
            <h6>There is miracle for you</h6>
        </div>
        <span><i></i><i></i></span>
    </div>
    <img src="assets/images/bottom-left-acc.svg" class="login-left-item" />
</div>
<div *ngIf="fetchBranchData$ | async" class="d-flex flex-column">
    <mat-drawer-container autosize class="h-100">
        <mat-drawer #drawer
            mode="side"
            opened
            class="navbar sidenav-container"
            [ngClass]="{'expanded': expandMenu}">
            <app-left-menu></app-left-menu>
        </mat-drawer>
        <mat-drawer-content class="d-flex flex-column">
            <div class="content-wrapper">
                <router-outlet></router-outlet>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>