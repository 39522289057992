<section class="app-header" [ngClass]="!expandMenu ? 'expand':''">
    <app-header></app-header>
    <div class="d-flex flex-row home-page-header"
        [ngClass]="!expandMenu ? 'expand':''">
        <div class="d-flex flex-grow-1">
            <nav mat-tab-nav-bar>
                <a mat-tab-link *ngFor="let link of links"
                    [routerLink]="link.link"
                    (click)="activeLink = link.link"
                    [active]="activeLink == link.link">
                    {{ link.label }}
                </a>
            </nav>
        </div>
        <div matRipple 
            [matMenuTriggerFor]="branchList"
            class="d-flex flex-row branch-list justify-content-center align-items-center">
            <img src="assets/images/icon-home-black.png" width="18" height="18" />
            <span class="branch-name ml-2 mr-3">{{ branchData.branchName }}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
        <mat-menu #branchList="matMenu" yPosition="above">
            <div class="search-input-div">
                <div style="position: relative;" (click)="$event.stopPropagation()">
                    <input #txtKeyword
                        [(ngModel)]="searchInput"
                        class="search-input-branch"
                        placeholder ="Search Branch"
                        (keyup)="onSearchBranch($event.target.value)"
                        (click)="$event.stopPropagation()"
                        (blur)="onBlur()" />
                    <mat-icon matRipple class="icon-clear-search-home pointer" 
                        (click)="onResetFilter()">clear</mat-icon>
                </div>
            </div>

            <div *ngFor="let branch of selectedBranchDataList">
                <button mat-menu-item 
                    [ngClass]="branch.branchID == branchData.branchID ? 'active-item':''"
                    class="btn-branch"
                    (click)="onClickBranch(branch)">{{ branch.branchName }}</button>
            </div>
        </mat-menu>
    </div>
    <router-outlet></router-outlet>
</section>