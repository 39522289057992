<div class="d-flex flex-column panel-filter-status">
    <div class="d-flex flex-row header">
        <mat-icon class="icon-info">info</mat-icon>
        <span>Can choose multiple</span>
    </div>
    <div class="d-flex flex-column flex-grow-1 content">
        <div class="d-flex flex-row align-items-center justify-content-center"
            style="padding: 0 12px 0 12px;">
            <div matRipple
                class="d-flex align-items-center justify-content-center filter-status-items pointer w-100"
                [ngClass]="getClass('New')"
                (click)="onClickItem('New')">
                New
            </div>
            <div matRipple
                class="d-flex align-items-center justify-content-center filter-status-items pointer w-100"
                [ngClass]="getClass('Confirmed')"
                (click)="onClickItem('Confirmed')">
                Confirmed
            </div>
        </div>
        <div class="d-flex flex-row align-items-center justify-content-center"
            style="padding: 0 12px 0 12px;">
            <div matRipple
                class="d-flex align-items-center justify-content-center filter-status-items pointer w-100"
                [ngClass]="getClass('Rejected')"
                (click)="onClickItem('Rejected')">
                Rejected
            </div>
            <div matRipple
                class="d-flex align-items-center justify-content-center filter-status-items pointer w-100"
                [ngClass]="getClass('Cancelled')"
                (click)="onClickItem('Cancelled')">
                Cancelled
            </div>
        </div>
        <div class="d-flex flex-row align-items-center justify-content-center"
            style="padding: 0 12px 0 12px;">
            <div matRipple
                class="d-flex align-items-center justify-content-center filter-status-items pointer w-100"
                [ngClass]="getClass('All')"
                (click)="onClickItem('All')">
                All
            </div>
            <div matRipple
                class="d-flex align-items-center justify-content-center filter-status-items pointer w-100"
                [ngClass]="getClass('Finished')"
                (click)="onClickItem('Finished')">
                Finished
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="d-flex flex-row align-items-center justify-content-center"
            style="padding: 0 12px 0 12px;">
            <div matRipple
                class="d-flex align-items-center justify-content-center filter-status-cancel pointer w-100"
                (click)="onCancel()">
                Cancel
            </div>
            <div matRipple
                class="d-flex align-items-center justify-content-center filter-status-select pointer w-100"
                (click)="onSelectStatus()">
                Select
            </div>
        </div>
    </div>
</div>