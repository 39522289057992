import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { HttpService } from './http.service';
import { tap } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { BookChannel } from '../models/book-channel.model';
import { Branch } from '../models/branch.model';
import { SalesMode } from '../models/sales-mode.model';
import { TableSection } from '../models/table.model';
import { VisitorType } from '../models/visitor-type.model';

@Injectable({
  providedIn: 'root'
})
export class BranchDataService {
  activeBranchData$ = new BehaviorSubject<Branch>(null);
  branchData$ = new BehaviorSubject<Branch[]>(null);
  bookChannel$ = new BehaviorSubject<BookChannel[]>(null);
  salesMode$ = new BehaviorSubject<SalesMode[]>(null);
  tableSection$ = new BehaviorSubject<TableSection[]>(null);
  visitorType$ = new BehaviorSubject<VisitorType[]>(null);

  constructor(private http: HttpService) { }

  /**
   * Fetch Branch data.
   */
  fetchBranchData() {
    return this.http.privatePost<Branch[]>(AppSettings.getBranchUrl).pipe(
      tap((data: Branch[]) => this.branchData$.next(data))
    );
  }

  /**
   * Fetch Book Channel data.
   */
  fetchBookChannelData() {
    return this.http.privatePost<BookChannel[]>(AppSettings.getBookChannelUrl).pipe(
      tap((data: BookChannel[]) => this.bookChannel$.next(data))
    );
  }

  /**
   * Fetch Sales Mode data.
   */
  fetchSalesModeData(branchID: number) {
    return this.http.privatePost<SalesMode[]>(AppSettings.getSalesMode, {
      branchID: branchID
    }).pipe(
      tap((data: SalesMode[]) => this.salesMode$.next(data))
    );
  }

  /**
   * Fetch Table List data.
   */
  fetchTableListData(branchID: number, bookDate: string, bookTime: string) {
    return this.http.privatePost<TableSection[]>(AppSettings.getTableSectionList, {
      branchID: branchID,
      bookDate: bookDate,
      bookTime: bookTime
    }).pipe(
      tap((data: TableSection[]) => {
        const tableSection = data.filter(ts => ts.table.length > 0);
        this.tableSection$.next(tableSection);
      })
    );
  }

  /**
   * Fetch Visitor Type data.
   */
  fetchVisitorTypeData(branchID: number) {
    return this.http.privatePost<VisitorType[]>(AppSettings.getVisitorType, {
      branchID: branchID
    }).pipe(
      tap((data: VisitorType[]) => this.visitorType$.next(data))
    );
  }

  setActiveBranch(branch: Branch) {
    this.removeLocalStorage('branch');
    this.saveLocalStorage('branch', JSON.stringify(branch))
    this.activeBranchData$.next(branch);
  }

  saveLocalStorage(key: string, value: string) {
    localStorage.setItem(btoa(key), btoa(value));
  }

  loadLocalStorage(key: string) {
    const encryptedValue = localStorage.getItem(btoa(key));
    if (!encryptedValue) {
      return null;
    }
    try {
      const result = atob(encryptedValue);
      return result;
    } catch (exception) {
      localStorage.clear();
      return null;
    }
  }

  /**
   * remove active branch in localstorage.
   */
   removeLocalStorage(key: string) {
    localStorage.removeItem(btoa(key));
    this.activeBranchData$.next(null);
  }
}
