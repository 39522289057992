import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpService } from './http.service';
import { User } from '../models/user.model';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedInUser$ = new BehaviorSubject<User>(null);

  constructor(private http: HttpService) { }

  checkReferrer(baseUrl: string) {
    if (baseUrl) {
      this.http.setReferrer(baseUrl);
    }
  }

  checkSession(userAuthKey: string) {
    if (userAuthKey) {
      this.http.setUserToken(userAuthKey);
    }

    if (this.http.getReferrer() === '') {
      return of(null);
    }

    return this.http.privatePost<User>(AppSettings.getUserInfoUrl).pipe(
      tap((data: User) => this.loggedInUser$.next(data))
    );
  }
}
