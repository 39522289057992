<div class="d-flex flex-grow-1 flex-column">
    <div class="d-flex flex-grow-1 flex-row card" style="flex-wrap: wrap;">
        <div style="position: relative;">
            <img *ngIf="selectedSection?.image"
                [src]="selectedSection.image"
                style="max-height: 407px;" />
            <div style="position:absolute; top: 0px; left: 0px;">
                <button *ngFor="let dataTable of selectedSection.table; trackBy: trackByFn"
                    mat-raised-button
                    [ngClass]="['d-flex flex-column justify-content-center align-items-center table', dataTable.tableTypeClass, dataTable.tableStatusClass]"
                    [disabled]="disableButton(dataTable.tableStatusClass)"
                    [ngStyle]="getTableLocation(dataTable)"
                    (click)="onClickTable(dataTable)">
                    {{ dataTable.tableName }}
                </button>
            </div>
        </div>
    </div>
</div>