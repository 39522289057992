import { DOCUMENT, formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'lodash';
import * as moment from 'moment';

import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AddBook, ReturnMessage } from 'src/app/models/add-book.model';
import { BookChannel } from 'src/app/models/book-channel.model';
import { BookHistory } from 'src/app/models/book-history.model';
import { Branch } from 'src/app/models/branch.model';
import { SalesMode } from 'src/app/models/sales-mode.model';
import { Table, TableSection } from 'src/app/models/table.model';
import { VisitorType } from 'src/app/models/visitor-type.model';

import { AddBookService } from 'src/app/services/add-book.service';
import { BranchDataService } from 'src/app/services/branch-data.service';
import { UiService } from 'src/app/services/ui.service';

import { TableDetailsComponent } from 'src/app/shared/table-details/table-details.component';
import { BookingConfirmationComponent } from './booking-confirmation/booking-confirmation.component';

@Component({
  selector: 'app-add-booking',
  templateUrl: './add-booking.component.html',
  styleUrls: ['./add-booking.component.scss']
})
export class AddBookingComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  bookChannelDataList: BookChannel[];
  bookDate: string;
  bookTime: string;
  bookingDetail: BookHistory;
  bookNumDisplay: string;
  branchData: Branch;
  branchDataList: Branch[];
  checked: boolean;
  currentBook: AddBook;
  expandMenu: boolean;
  form: FormGroup;
  guestEmail: string;
  guestName: string;
  isAdvanceSetting = 'No';
  isBookChannelValidated = false;
  isBookTimeValidated = true;
  isFullBooked: boolean;
  isSalesModeValidated = false;
  isUnavailableTable: boolean;
  isVisitorTypeValidated = false;
  minDate: Date;
  minTime: string;
  mode = 'add';
  paxTotal = 1;
  searchInput: string;
  outletNotes: string;
  salesModeDataList: SalesMode[];
  selectedBookChannel: number;
  selectedBranchID: number;
  selectedBranchDataList: Branch[];
  selectedSalesMode: number;
  selectedSection: TableSection;
  selectedTableList: Table[];
  selectedVisitorType: number;
  tableSectionDataList: TableSection[];
  visitorTypeDataList: VisitorType[];
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto');

  bookChannelIconList = [
    "<i class='esb-icon-booking-call'></i>",
    "<i class='esb-icon-booking-visit'></i>"
  ];

  titleList = [
    "Mr",
    "Ms",
    "Mrs"
  ];

  constructor(private addBookService: AddBookService, 
    private branchDataService: BranchDataService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private uiService: UiService,
    @Inject(DOCUMENT) private document: any) { }
  
  ngOnInit() {
    this.uiService.expandMenu$.subscribe(
      (data: boolean) => {
        this.expandMenu = data;
      }
    );

    this.branchDataService.activeBranchData$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: Branch) => {
        this.branchData = data;
        this.selectedBranchID = data.branchID;
        const formattedDate = this.form ? this.form.get('date').value : new Date();
        const momentDate = new Date(formattedDate);
        const bookDate = moment(momentDate).format("YYYY-MM-DD");
        const bookTime = this.form ? this.form.get('bookTime').value : formatDate(new Date(), 'HH:mm', 'en-US');

        if (this.selectedBranchID && bookDate && bookTime) {
          this.getTableList(this.selectedBranchID, bookDate, bookTime);
        }

        this.branchDataService.fetchSalesModeData(this.selectedBranchID).subscribe();
      }
    );

    this.bookNumDisplay = this.route.snapshot.params['booknumdisplay'];
    if (this.bookNumDisplay) {
      this.mode = 'edit';
      
      this.addBookService.fetchBookDetail(this.branchData.branchID, this.bookNumDisplay).pipe(
        takeUntil(this.onDestroy$),
        map((data: BookHistory) => {
          this.bookingDetail = data[0];
          this.initBookingDetailData();
        })
      ).subscribe();
    }

    this.initData();

    this.branchDataService.salesMode$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: SalesMode[]) => {
        this.salesModeDataList = data;
      }
    );

    this.branchDataService.tableSection$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: TableSection[]) => {
        if (data) {
          this.tableSectionDataList = data;
          this.onClickSection(this.tableSectionDataList[0]);
        }
      }
    );

    this.addBookService.addBook$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: AddBook) => {
        this.currentBook = data;
      }
    );

    this.bookChannelDataList = this.branchDataService.bookChannel$.value;
    this.branchDataList = this.branchDataService.branchData$.value;
    this.branchDataService.fetchVisitorTypeData(this.branchData.branchID).subscribe(
      (data: VisitorType[]) => {
        if(data){
          this.visitorTypeDataList = data;
        }
      }
    );
    this.selectedBranchDataList = this.branchDataList;
  }

  initData() {
    this.minTime = this.getMinTime();
    this.minDate = new Date();
    this.selectedBookChannel = 0;
    this.selectedBranchID = 0;
    this.selectedSalesMode = null;
    this.selectedVisitorType = null;
    this.selectedSection = null;
    this.selectedTableList = null;

    this.form = this.formBuilder.group({
      branchID: [this.selectedBranchID, Validators.required],
      title: [null, Validators.required],
      fullName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      phoneNumber: [null, Validators.required],
      pax: [this.paxTotal, Validators.required],
      date: [new Date(), Validators.required],
      bookTime: [this.minTime, Validators.required],
      outletNotes: [null],
      notes: [null]
    });

    this.addBookService.initBook();
  }

  initBookingDetailData() {
    if (this.bookingDetail) {
      this.isBookChannelValidated = true;
      this.isBookTimeValidated = true;
      this.selectedBookChannel = this.bookingDetail.bookChannel.bookChannelID;
      this.paxTotal = this.bookingDetail.paxTotal;
      this.bookDate = this.bookingDetail.bookDate;
      this.bookTime = this.bookingDetail.bookTime;
      this.outletNotes = this.bookingDetail.outletNotes;
      this.form.get('pax').setValue(this.paxTotal);
      this.form.get('date').setValue(this.bookDate);
      this.form.get('bookTime').setValue(this.bookTime);
      this.form.get('outletNotes').setValue(this.outletNotes);
    }
  }

  onClickBookChannel(channel: BookChannel) {
    this.selectedBookChannel = channel.bookChannelID;
    this.isBookChannelValidated = true;
  }

  onClickSalesMode(salesMode: SalesMode) {
    const bookData = this.addBookService.addBook$.value;

    bookData.visitPurposeID = salesMode.visitPurposeID;
    bookData.visitPurposeName = salesMode.visitPurposeName;
    this.selectedSalesMode = salesMode.visitPurposeID;
    this.isSalesModeValidated = true;
  }

  onClickSection(item: TableSection) {
    this.selectedSection = this.tableSectionDataList.find(table => table.tableSectionID === item.tableSectionID);
    let countTableSectionAvailibility = 0;
    let countTableAvailibility = 0;
    for (const tableSection of this.tableSectionDataList) {
      if (tableSection) {
        const tableSectionStatusAvailable = tableSection.tableSectionStatus == 'available';
        if (tableSectionStatusAvailable) {
          countTableSectionAvailibility += 1;
        }
        const tableStatusAvailable = tableSection.table.find(table => table.tableStatusClass == 'available');
        if (tableStatusAvailable) {
          countTableAvailibility = 1;
        }
      }
    }

    if (countTableSectionAvailibility > 0 && countTableAvailibility == 0) {
      this.isFullBooked = true;
      this.isUnavailableTable = false;
    } else if (countTableSectionAvailibility > 0 && countTableAvailibility > 0) {
      this.isFullBooked = false;
      this.isUnavailableTable = false;
    } else if (countTableSectionAvailibility == 0) {
      this.isFullBooked = false;
      this.isUnavailableTable = true;
    }
  }

  onClickTable(item: Table) {
    this.addBookService.addBook(item);
    for (const tables of this.selectedSection.table) {
      if (item.tableID == tables.tableID) {
        tables.tableStatusClass = 'book';
      }
    }
  }

  onClickVisitorType(visitorType: VisitorType) {
    const bookData = this.addBookService.addBook$.value;

    bookData.visitorTypeID = visitorType.visitorTypeID;
    bookData.visitorTypeName = visitorType.visitorTypeName;
    this.selectedVisitorType = visitorType.visitorTypeID;
    this.isVisitorTypeValidated = true;
  }

  onDeleteSalesMode() {
    const bookData = this.addBookService.addBook$.value;
    
    bookData.visitPurposeID = 0;
    bookData.visitPurposeName = '';
    this.selectedSalesMode = 0;
    if (!this.selectedSalesMode) {
      this.isSalesModeValidated = false;
    }
  }

  onDeleteVisitorType() {
    const bookData = this.addBookService.addBook$.value;
    
    bookData.visitorTypeID = 0;
    bookData.visitorTypeName = '';
    this.selectedVisitorType = 0;
  }

  onDeleteTable(item: Table) {
    this.addBookService.deleteTable(item);
    for (const tableSection of this.tableSectionDataList) {
      for (const table of tableSection.table) {
        if (item.tableID == table.tableID) {
          table.tableStatusClass = 'available';
        }
      }
    }
  }

  onOpenTableDetails() {
    const branchID = this.form.get('branchID').value;
    const formattedDate = this.form.get('date').value;
    const momentDate = new Date(formattedDate);
    const bookDate = moment(momentDate).format("DD-MM-YYYY");

    this.dialog.open(TableDetailsComponent, {
      data: {
        branchID: branchID,
        bookDate: bookDate
      },
      disableClose: true,
      id: 'TableDetailsComponent',
      autoFocus: false,
      panelClass: 'table-details'
    });
  }

  onSearchBranch(value: string) {
    if (value) {
      this.selectedBranchDataList = this.search(value);
      if (this.selectedBranchDataList.length == 0) {
        this.selectedBranchDataList = this.branchDataList;
      }
    } else {
      this.selectedBranchDataList = this.branchDataList;
    }
  }

  onResetFilter() {
    this.searchInput = '';
    this.selectedBranchDataList = this.branchDataList;
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  onToggle(event: any) {
    if (event.checked) {
      this.isAdvanceSetting = 'Yes';
    } else {
      const branchID = this.form.get('branchID').value;
      const formattedDate = this.form.get('date').value;
      const momentDate = new Date(formattedDate);
      const bookDate = moment(momentDate).format("YYYY-MM-DD");
      const bookTime = this.form.get('bookTime').value;
      this.selectedSalesMode = null;
      this.selectedVisitorType = null;
      this.selectedTableList = null;
      this.currentBook.visitPurposeID = null;
      this.currentBook.visitorTypeID = null;

      if (branchID && bookDate && bookTime) {
        this.getTableList(branchID, bookDate, bookTime);
        this.addBookService.deleteAllTable();
      }
      this.isAdvanceSetting = 'No';
    }
  }

  branchChanged() {
    const branchID = this.form.get('branchID').value;
    const formattedDate = this.form.get('date').value;
    const momentDate = new Date(formattedDate);
    const bookDate = moment(momentDate).format("YYYY-MM-DD");
    const bookTime = this.form.get('bookTime').value;
    const currentDate = new Date('YYYY-MM-DD');

    if (branchID && bookDate && bookTime) {
      this.selectedTableList = null;
      this.getTableList(branchID, bookDate, bookTime);
    }

    this.branchDataService.fetchSalesModeData(branchID).subscribe();
    this.addBookService.deleteAllTable();
  }

  dateChanged() {
    const branchID = this.form.get('branchID').value;
    const formattedDate = this.form.get('date').value;
    const momentDate = new Date(formattedDate);
    const bookDate = moment(momentDate).format("YYYY-MM-DD");
    const bookTime = this.form.get('bookTime').value;
    const currentDate = new Date();

    if (branchID && bookDate && bookTime) {
      this.getTableList(branchID, bookDate, bookTime);
      this.addBookService.deleteAllTable();
    }

    if (currentDate < momentDate) {
      this.minTime = '00:00';
    } else {
      this.minTime = this.getMinTime();
    }
  }

  getTableList(branchID: number, bookDate: string, bookTime: string) {
    this.branchDataService.fetchTableListData(branchID, bookDate, bookTime).subscribe();
  }

  getBranchError() {
    const branchID = this.form.get('branchID');
    return this.form.get('branchID').hasError('required') ? 'You must select branch' : '';
  }

  getTitleError() {
    return this.form.get('title').hasError('required') ? 'You must select title' : '';
  }

  getFullNameError() {
    return this.form.get('fullName').hasError('required') ? 'You must enter a name' : '';
  }

  getEmailError() {
    const email = this.form.get('email');
    return email.hasError('required') ? 'You must enter an email' :
      email.hasError('email') ? 'Not a valid email' : '';
  }

  getPhoneNumberError() {
    const phoneNumber = this.form.get('phoneNumber');
    return phoneNumber.hasError('required') ? 'You must enter a phone number' :
      phoneNumber.hasError('pattern') || phoneNumber.hasError('minlength') ? 'Not a valid phone number' : '';
  }

  getPaxError() {
    const pax = this.form.get('pax');
    return pax.hasError('required') ? 'You must enter pax' : 
      pax.hasError('pattern') || pax.hasError('minlength') ? 'Not a valid pax' : '';
  }

  getDateError() {
    return this.form.get('date').hasError('required') ? 'You must enter date' : '';
  }

  getBookTimeError() {
    const bookTime = this.form.get('bookTime');
    return bookTime.hasError('required') ? 'You must enter minutes' : 
      bookTime.hasError('min') ? 'Not a valid minute' : '';
  }

  getMinTime() {
    let defaultTime = formatDate(new Date(), 'HH', 'en-US');
    let defaultTimeNumber = +defaultTime + 1;
    return defaultTimeNumber + ':00';
  }

  disableButton() {
    let result = false;
    if (this.isAdvanceSetting == 'Yes') {
      if (!this.isSalesModeValidated || (this.visitorTypeDataList.length > 0 && !this.isVisitorTypeValidated) || this.currentBook.bookTable.length == 0) {
        result = true
      }
    } else if (this.isAdvanceSetting == 'No') {
      result = false;
    }
    return result;
  }

  search(value: string) { 
    let filter = value.toLowerCase();
    return this.branchDataList.filter(option => option.branchName.toLowerCase().includes(filter));
  }

  async onSave() {
    const bookData = this.addBookService.addBook$.value;
    const branchID = this.form.get('branchID').value;
    let tempBookingStatus = 'new';
    let isValidated = true;
    if (this.mode == 'add') {
      if (this.isAdvanceSetting == 'No') {
        if (this.form.invalid || !this.isBookChannelValidated || !this.isBookTimeValidated) {
          isValidated = false;
        }
      } else if (this.isAdvanceSetting == 'Yes') {
        if (this.form.invalid || !this.isBookChannelValidated || !this.isBookTimeValidated || 
            !this.isSalesModeValidated || (this.visitorTypeDataList.length > 0 && !this.isVisitorTypeValidated)) {
          isValidated = false;
        }
      }
    }

    if (this.selectedSalesMode && bookData.bookTable.length > 0 || (this.visitorTypeDataList.length > 0 && this.selectedVisitorType)) {
      tempBookingStatus = 'confirm';
    }

    if (branchID == 0) {
      this.form.get('branchID').setValue('');
    }
    
    if (!isValidated) {
      this.form.markAllAsTouched();
      const el = this.document.getElementById('book-form');
      el.scrollIntoView({ behavior: 'smooth' });
      this.spinner.hide();
      return;
    } else {
      let confirm: any;
      if (tempBookingStatus == 'new') {
        confirm = await this.uiService.showConfirm('new-add', 'Submit This Booking?',
          "A confirmation email will be automatically sent to customer's email.", true);
      } else if (tempBookingStatus == 'confirm') {
        confirm = await this.uiService.showConfirm('confirm-add', 'Add and Confirm this booking?',
          "A confirmation email will be automatically sent to customer's email.", true);
      }

      if (confirm) {
        this.spinner.show();
        const formattedDate = this.form.get('date').value;
        const momentDate = new Date(formattedDate);
        const bookDate = moment(momentDate).format("DD-MM-YYYY");
        const bookTime = this.form.get('bookTime').value;

        if (this.mode == 'add') {
          bookData.bookDate = bookDate;
          bookData.bookChannelID = this.selectedBookChannel;
          bookData.branchID = branchID;
          bookData.title = this.form.get('title').value;
          bookData.customerName = this.uiService.removeInvalidChars(this.form.get('fullName').value);
          bookData.email = this.uiService.removeInvalidChars(this.form.get('email').value);
          bookData.phoneNumber = this.uiService.removeInvalidChars(this.form.get('phoneNumber').value);
          bookData.paxTotal = this.form.get('pax').value;
          bookData.outletNotes = this.form.get('outletNotes').value;
          bookData.notes = this.uiService.removeInvalidChars(this.form.get('notes').value);
          bookData.bookTime = bookTime;
          bookData.visitPurposeID = this.selectedSalesMode;
          bookData.visitorTypeID = this.selectedVisitorType;
        } else if (this.mode == 'edit') {
          bookData.bookDate = bookDate;
          bookData.paxTotal = this.form.get('pax').value;
          bookData.bookTime = bookTime;
          bookData.bookNum = this.bookingDetail.bookNum;
          bookData.bookChannelID = this.bookingDetail.bookChannel.bookChannelID;
          bookData.branchID = this.bookingDetail.branch.branchID;
          bookData.title = this.bookingDetail.title;
          bookData.customerName = this.bookingDetail.customerName;
          bookData.email = this.bookingDetail.email;
          bookData.phoneNumber = this.bookingDetail.phoneNumber;
          bookData.outletNotes =  this.form.get('outletNotes').value;
          bookData.notes = this.bookingDetail.notes;

          delete bookData.visitPurposeID;
          delete bookData.visitorTypeID;
          delete bookData.bookTable;
        }

        this.addBookService.bookTable(bookData).subscribe(
          (data: ReturnMessage) => {
            if (data.success) {
              if (tempBookingStatus == 'confirm') {
                let bookedBranchID = branchID > 0 ? branchID : this.bookingDetail.branch.branchID;
                this.initData();
                this.addBookService.initBook();
                this.getTableList(bookedBranchID, bookDate, bookTime);
  
                if (data.bookNum) {
                  this.addBookService.fetchBookHistory(bookedBranchID, bookDate, bookDate).pipe(
                    map(() => {
                      this.spinner.hide();
                      const bookHistory = this.addBookService.getBookHistoryByBookNum(data.bookNum);
                      this.dialog.open(BookingConfirmationComponent, {
                        data: {
                          bookHistory
                        },
                        disableClose: true,
                        id: 'BookingConfirmationComponent',
                        autoFocus: false,
                        panelClass: 'dialog-confirmation'
                      });
                    })
                  ).subscribe();
                }
              } else if (tempBookingStatus == 'new') {
                this.spinner.hide();
                this.router.navigate(['/book/booking-list']);
              }
            } else {
              this.uiService.showAlert(null, data.message);
              this.spinner.hide();
            }
          },
          (error: HttpErrorResponse) => {
            let errMsg = 'Failed connect to server. Please Try Again.';
            if (error.error.message) {
              errMsg = error.error.message;
            }
            this.uiService.showAlert(null, errMsg);
            this.spinner.hide();
          }
        );
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
