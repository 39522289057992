<section class="app-header" [ngClass]="!expandMenu ? 'expand':''">
    <app-header></app-header>
    <div class="d-flex flex-row add-booking-header"
        [ngClass]="!expandMenu ? 'expand':''">
        <div class="d-flex flex-grow-1">
            <span *ngIf="mode == 'add'" class="header-text">Add Booking</span>
            <span *ngIf="mode == 'edit'" class="header-text">Edit Booking</span>
        </div>
    </div>
</section>
<div class="d-flex flex-column add-booking-page" [ngClass]="!expandMenu ? 'expand':''">
    <div class="d-flex flex-row panel-header-add-booking justify-content-center"
        [ngClass]="!expandMenu ? 'expand':''">
        <div class="d-flex flex-grow-1 flex-row align-items-center ">
            <span [routerLink]="'/book/booking-list'" 
                class="bootstrap-link">List of booking
            </span>
            <mat-icon>keyboard_arrow_right</mat-icon>
            <span *ngIf="mode == 'add'" style="margin-left: 10px;">Add Booking</span>
            <span *ngIf="mode == 'edit'" style="margin-left: 10px;">Edit Booking</span>
        </div>
    </div>

    <div class="add-spacer"></div>

    <div *ngIf="mode == 'edit'" class="edit-info">
        <span class="edit-info-text-1">You can be change just</span>
        <span class="edit-info-text-2"> date of booking, booking time, total pax & outlet notes</span>
    </div>

    <div id="book-form" class="d-flex flex-column form-add-booking">
        <div *ngIf="mode == 'add'">
            <div class="d-flex flex-row booking-channel-label align-items-center mb-2">
                <span>BOOKING CHANNEL</span>
                <img src="assets/images/icon-asterix.svg" style="width: 5px; margin-right: 5px; align-self: start;" />
                <i class="esb-icon-info info-icon pointer"
                    matTooltip="Select a booking channel for your customer"
                    [matTooltipPosition]="'above'"></i>
            </div>
            <div class="d-flex flex-row booking-channel-content">
                <div *ngFor="let bookChannel of bookChannelDataList; let i=index;"
                    class="d-flex booking-channel-option flex-grow-1 flex-row justify-content-center pointer"
                    [ngClass]="selectedBookChannel == bookChannel.bookChannelID ? 'active': 'inactive'"
                    (click)="onClickBookChannel(bookChannel)">
                    <div [innerHTML]="bookChannelIconList[i]"></div>
                    <span class="ml-2">{{ bookChannel.bookChannelName }}</span>
                </div>
            </div>
            <span *ngIf="!isBookChannelValidated" class="mat-error customer-error">You must select book channel</span>
        </div>

        <div *ngIf="mode == 'edit'">
            <div class="d-flex flex-row booking-channel-label align-items-center mb-2">
                <span>BOOKING CHANNEL</span>
                <img src="assets/images/icon-asterix.svg" style="width: 5px; margin-right: 5px; align-self: start;" />
                <i class="esb-icon-info info-icon pointer"
                    matTooltip="Select a booking channel for your customer"
                    [matTooltipPosition]="'above'"></i>
            </div>
            <div class="d-flex flex-row booking-channel-content">
                <div *ngFor="let bookChannel of bookChannelDataList; let i=index;"
                    class="d-flex booking-channel-edit-option flex-grow-1 flex-row justify-content-center"
                    [ngClass]="selectedBookChannel == bookChannel.bookChannelID ? 'active': 'inactive'">
                    <div [innerHTML]="bookChannelIconList[i]"></div>
                    <span class="ml-2">{{ bookChannel.bookChannelName }}</span>
                </div>
            </div>
        </div>

        <form [formGroup]="form"
            (ngSubmit)="onSave()">
            <div class="d-flex flex-row booking-branch-label">
                <span>BRANCH</span>
                <img src="assets/images/icon-asterix.svg" style="width: 5px; align-self: start;" />
            </div>
            <div *ngIf="mode == 'add'" class="d-flex flex-column booking-branch-content">
                <mat-form-field class="branch" appearance="standard" 
                    [hideRequiredMarker]="hideRequiredControl.value"
                    [floatLabel]="floatLabelControl.value">
                    <mat-select formControlName="branchID"
                        class="form-control"
                        placeholder="Please select a branch"
                        (selectionChange)="branchChanged()">
                        <div class="search-branch-div">
                            <div style="position: relative;">
                                <input [(ngModel)]="searchInput"
                                    [ngModelOptions]="{standalone: true}"
                                    class="search-branch-input w-100" placeholder ="Search Branch"
                                    (keyup)="onSearchBranch($event.target.value)" (keydown)="$event.stopPropagation()">
                                <img src="assets/images/icon-search.png" 
                                    class="icon-search pointer" width="14" height="14" />
                                <mat-icon matRipple class="icon-clear-search pointer" 
                                    (click)="onResetFilter()">clear</mat-icon>
                            </div>
                        </div>
                        <mat-option *ngFor="let branch of selectedBranchDataList"
                            [value]="branch.branchID">
                            {{ branch.branchName }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="isFieldValid('branchID')">{{getBranchError()}}</mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="mode == 'edit'" class="d-flex flex-column booking-branch-content">
                <mat-form-field class="branch" appearance="standard" 
                    [hideRequiredMarker]="hideRequiredControl.value"
                    [floatLabel]="floatLabelControl.value">
                    <input matInput
                        [value]="bookingDetail?.branch.branchName"
                        class="form-control text-left branch-edit-content"
                        [disabled]="true" />
                </mat-form-field>
            </div>

            <div class="d-flex flex-column booking-guest-info">
                <div class="d-flex flex-row" style="width: 100%;">
                    <div class="d-flex guest-row-1">
                        <div class="d-flex flex-column guest-title">
                            <div class="d-flex flex-row guest-title-label">
                                <span>TITLE</span>
                                <img src="assets/images/icon-asterix.svg" style="width: 5px; align-self: start;" />
                            </div>
                            <div *ngIf="mode == 'add'" class="d-flex guest-title-content">
                                <mat-form-field class="guestTitle" appearance="standard" 
                                    [hideRequiredMarker]="hideRequiredControl.value"
                                    [floatLabel]="floatLabelControl.value">
                                    <mat-select formControlName="title"
                                        class="form-control"
                                        placeholder="Mr, Ms/Mrs">
                                        <mat-option *ngFor="let title of titleList" [value]="title">
                                            {{ title }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="isFieldValid('title')">{{getTitleError()}}</mat-error>
                                </mat-form-field>
                            </div>

                            <div *ngIf="mode == 'edit'" class="d-flex guest-title-content">
                                <mat-form-field class="guestTitle" appearance="standard" 
                                    [hideRequiredMarker]="hideRequiredControl.value"
                                    [floatLabel]="floatLabelControl.value">
                                    <input matInput
                                        [value]="bookingDetail?.title"
                                        class="form-control text-left title-edit-content"
                                        [disabled]="true" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="d-flex flex-column guest-name">
                            <div class="d-flex flex-row guest-name-label">
                                <span>CUSTOMER NAME</span>
                                <img src="assets/images/icon-asterix.svg" style="width: 5px; align-self: start;" />
                            </div>
                            <div *ngIf="mode == 'add'">
                                <mat-form-field appearance="standard" 
                                    [hideRequiredMarker]="hideRequiredControl.value"
                                    [floatLabel]="floatLabelControl.value">
                                    <input matInput
                                        formControlName="fullName"
                                        placeholder="Enter customer name"
                                        class="form-control text-left guest-name-content" />
                                    <mat-error *ngIf="isFieldValid('fullName')">{{getFullNameError()}}</mat-error>
                                </mat-form-field>
                            </div>

                            <div *ngIf="mode == 'edit'">
                                <mat-form-field appearance="standard" 
                                    [hideRequiredMarker]="hideRequiredControl.value"
                                    [floatLabel]="floatLabelControl.value">
                                    <input matInput
                                        [value]="bookingDetail?.customerName"
                                        class="form-control text-left guest-name-content"
                                        [disabled]="true" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex guest-row-2">
                        <div class="d-flex flex-column guest-email">
                            <div class="d-flex flex-row guest-email-label">
                                <span>CUSTOMER EMAIL</span>
                                <img src="assets/images/icon-asterix.svg" style="width: 5px; align-self: start;" />
                            </div>
                            <div *ngIf="mode == 'add'">
                                <mat-form-field appearance="standard" 
                                    [hideRequiredMarker]="hideRequiredControl.value"
                                    [floatLabel]="floatLabelControl.value">
                                    <input matInput
                                        formControlName="email"
                                        type="email"
                                        placeholder="Enter customer email"
                                        class="form-control text-left guest-email-content" />
                                    <mat-error *ngIf="isFieldValid('email')">{{getEmailError()}}</mat-error>
                                </mat-form-field>
                            </div>
                            
                            <div *ngIf="mode == 'edit'">
                                <mat-form-field appearance="standard" 
                                    [hideRequiredMarker]="hideRequiredControl.value"
                                    [floatLabel]="floatLabelControl.value">
                                    <input matInput
                                        [value]="bookingDetail?.email"
                                        type="email"
                                        class="form-control text-left guest-email-content"
                                        [disabled]="true" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row" style="width: 100%; margin-top: 1rem;">
                    <div class="d-flex guest-row-1">
                        <div class="d-flex flex-column guest-phone">
                            <div class="d-flex flex-row guest-phone-label">
                                <span>PHONE NUMBER</span>
                                <img src="assets/images/icon-asterix.svg" style="width: 5px; align-self: start;" />
                            </div>
                            <div *ngIf="mode == 'add'">
                                <mat-form-field appearance="standard" 
                                    [hideRequiredMarker]="hideRequiredControl.value"
                                    [floatLabel]="floatLabelControl.value">
                                    <input matInput
                                        formControlName="phoneNumber"
                                        minlength=9 maxlength=14 inputmode="numeric" pattern="[0-9]*"
                                        placeholder="08XX-XXXX-XXXX"
                                        class="form-control text-left guest-phone-content" />
                                    <mat-error *ngIf="isFieldValid('phoneNumber')">{{getPhoneNumberError()}}</mat-error>
                                </mat-form-field>
                            </div>
                            
                            <div *ngIf="mode == 'edit'">
                                <mat-form-field appearance="standard" 
                                    [hideRequiredMarker]="hideRequiredControl.value"
                                    [floatLabel]="floatLabelControl.value">
                                    <input matInput
                                        [value]="bookingDetail?.phoneNumber"
                                        class="form-control text-left guest-phone-content"
                                        [disabled]="true" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex guest-row-2">
                        <div class="d-flex flex-column guest-pax">
                            <div class="d-flex flex-row guest-pax-label">
                                <span>TOTAL PAX</span>
                                <img src="assets/images/icon-asterix.svg" style="width: 5px; align-self: start;" />
                                <i class="esb-icon-info info-icon pointer"
                                    matTooltip="The total number of incoming customers"
                                    [matTooltipPosition]="'above'"></i>
                            </div>
                            <mat-form-field appearance="standard" 
                                [hideRequiredMarker]="hideRequiredControl.value"
                                [floatLabel]="floatLabelControl.value">
                                <input matInput
                                    formControlName="pax"
                                    [value]="paxTotal"
                                    type="number" minlength=1 maxlength=10 inputmode="numeric" pattern="[0-9]*"
                                    placeholder="Total visitors"
                                    class="form-control text-left guest-pax-content" />
                                <mat-error *ngIf="isFieldValid('pax')">{{getPaxError()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row" style="width: 100%; margin-top: 1rem;">
                <div class="d-flex flex-column booking-date">
                    <div class="d-flex flex-row booking-date-label">
                        <span>DATE OF BOOK</span>
                        <img src="assets/images/icon-asterix.svg" style="width: 5px; align-self: start;" />
                    </div>
                    <div class="d-flex flex-row flex-grow-1">
                        <mat-form-field appearance="standard" 
                            [hideRequiredMarker]="hideRequiredControl.value"
                            [floatLabel]="floatLabelControl.value">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <img matDatepickerToggleIcon src="assets/images/icon-calendar.png" style="width: 20px;" />
                            </mat-datepicker-toggle>
                            <input matInput [matDatepicker]="picker"
                                formControlName="date"
                                [min]="minDate"
                                placeholder="Booking Date"
                                (dateChange)="dateChanged()"
                                (click)="picker.open()"
                                class="form-control text-left date-content" readonly />
                            <mat-datepicker #picker>
                                <mat-datepicker-actions>
                                    <button mat-flat-button matDatepickerCancel
                                        class="btn-date-cancel">Cancel</button>
                                    <button mat-flat-button matDatepickerApply
                                        class="btn-date-apply">Apply</button>
                                </mat-datepicker-actions>
                            </mat-datepicker>
                            <mat-error *ngIf="isFieldValid('date')">{{getDateError()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="d-flex flex-column booking-time">
                    <div class="d-flex flex-row booking-time-label">
                        <span>TIME</span>
                        <img src="assets/images/icon-asterix.svg" style="width: 5px; align-self: start;" />
                    </div>
                    <div class="d-flex flex-row">
                        <div class="d-flex book-time">
                            <input matInput [ngxTimepicker]="timepicker"
                                formControlName="bookTime"
                                [format]="24"
                                (ngModelChange)="dateChanged()"
                                [min]="minTime"
                                class="form-control text-left book-time-content"
                                readonly>
                            <ngx-material-timepicker #timepicker
                                [minutesGap]="30" [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
                            </ngx-material-timepicker>
                            <ng-template #cancelBtn>
                                <button matRipple type="button" class="timepicker-button-cancel">CANCEL</button>
                            </ng-template>
                            <ng-template #confirmBtn>
                                <button matRipple type="button" class="timepicker-button-confirm">OK</button>
                            </ng-template>
                            <mat-icon class="icon-clock">access_time</mat-icon>
                        </div>
                    </div>
                    <span *ngIf="!isBookTimeValidated" class="mat-error time-error">You must enter book time</span>
                </div>
            </div>
            <div class="d-flex flex-row" style="width: 50%; margin-top: 1rem;">
                <div class="d-flex flex-column booking-note">
                    <div class="d-flex flex-row booking-note-label">
                        <span style="margin-right: 5px;">OUTLET NOTES</span>
                        <i class="esb-icon-info info-icon pointer"
                            matTooltip="This information is only shown for the outlet"
                            [matTooltipPosition]="'above'"></i>
                    </div>
                    <div *ngIf="mode == 'add'" class="d-flex flex-row flex-grow-1">
                        <mat-form-field appearance="standard" 
                            [hideRequiredMarker]="hideRequiredControl.value"
                            [floatLabel]="floatLabelControl.value">
                            <input matInput maxlength="100"
                                formControlName="outletNotes"
                                class="form-control text-left outlet-notes-content" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="mode == 'edit'" class="d-flex flex-row flex-grow-1">
                        <mat-form-field appearance="standard" 
                            [hideRequiredMarker]="hideRequiredControl.value"
                            [floatLabel]="floatLabelControl.value">
                            <input matInput maxlength="100"
                                [value]="bookingDetail?.outletNotes"
                                formControlName="outletNotes"
                                class="form-control text-left outlet-notes-content"/>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row" style="width: 100%; margin-top: 1rem;">
                <div class="d-flex flex-column booking-note">
                    <div class="d-flex flex-row booking-note-label">
                        <span style="margin-right: 5px;">NOTE</span>
                        <i class="esb-icon-info info-icon pointer"
                            matTooltip="Enter customer's special request(s) here"
                            [matTooltipPosition]="'above'"></i>
                    </div>
                    <div *ngIf="mode == 'add'" class="d-flex flex-row flex-grow-1">
                        <mat-form-field appearance="standard" 
                            [hideRequiredMarker]="hideRequiredControl.value"
                            [floatLabel]="floatLabelControl.value">
                            <textarea matInput maxlength="1000"
                                formControlName="notes"
                                rows="7"
                                placeholder="Input note from customer"
                                class="form-control booking-note-content">
                            </textarea>
                        </mat-form-field>
                    </div>

                    <div *ngIf="mode == 'edit'" class="d-flex flex-row flex-grow-1">
                        <mat-form-field appearance="standard" 
                            [hideRequiredMarker]="hideRequiredControl.value"
                            [floatLabel]="floatLabelControl.value">
                            <textarea matInput maxlength="1000"
                                [value]="bookingDetail?.notes"
                                rows="7"
                                class="form-control booking-note-content"
                                [disabled]="true">
                            </textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="add-spacer"></div>

    <div *ngIf="mode == 'add'" class="d-flex flex-row section-advance-setting">
        <div class="d-flex flex-column flex-grow-1 justify-content-center">
            <div class="d-flex flex-row">
                <span style="margin-right: 5px; font-size: .9rem; font-weight: bold;">
                    Confirm Order?
                </span>
                <i style="font-size: .9rem;" class="esb-icon-info info-icon pointer"
                    matTooltip="In order to confirm your booking status, please complete details below"
                    [matTooltipPosition]="'above'"></i>
            </div>
        </div>
        <div class="d-flex flex-row align-items-center" style="margin-right: 2rem;">
            <span class="advance-status-text">{{ isAdvanceSetting }}</span>
            <mat-slide-toggle class="advance-toggle" 
                [checked]="checked" 
                (change)="onToggle($event)">
            </mat-slide-toggle>
        </div>
    </div>

    <div *ngIf="isAdvanceSetting == 'Yes'" class="d-flex flex-column form-advance-setting">
        <div class="d-flex flex-column section-sales-mode mt-3">
            <div class="d-flex flex-row sales-mode-label">
                <span>SALES MODE (VISIT PURPOSE)</span>
                <img src="assets/images/icon-asterix.svg" style="width: 5px; align-self: start; margin-right: 5px;" />
                <i class="esb-icon-info info-icon pointer"
                    matTooltip="Select with the appropriate transaction method"
                    [matTooltipPosition]="'above'">
                </i>
            </div>
            <div *ngIf="!(salesModeDataList && salesModeDataList.length > 0)"
                class="d-flex align-items-center book-info-section mt-2">
                <div class="d-flex flex-grow-1 align-items-center justify-content-center book-info">
                    <div class="d-flex align-items-center flex-grow-1 icon-text-alert">
                        <img src="assets/images/icon-warning.png" 
                            style="width: 25px; margin-right: 10px; align-self: start;" />
                        <span class="book-info-text">Please activate visit purpose in the branch configuration</span>
                    </div>
                </div>
            </div>
            
            <div *ngIf="salesModeDataList && salesModeDataList.length > 0">
                <div *ngIf="currentBook.visitPurposeID" class="d-flex flex-row" style="padding: 20px 0px 10px 0;">
                    <div class="d-flex flex-column justify-content-center align-items-center sales-mode-selected-info">
                        <span>{{ currentBook.visitPurposeName }}</span>
                        <button matRipple class="round-button delete-button pointer"
                            (click)="onDeleteSalesMode()">
                            <mat-icon class="ic-delete">close</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="sales-mode-content">
                    <div *ngFor="let salesMode of salesModeDataList; let i=index;"
                        (click)="onClickSalesMode(salesMode)"
                        class="sales-mode-item pointer"
                        [ngClass]="selectedSalesMode == salesMode.visitPurposeID ? 'active': 'inactive'">
                        {{ salesMode.visitPurposeName }}
                    </div>
                </div>
                <span *ngIf="!isSalesModeValidated" class="mat-error">You must select sales mode</span>
            </div>
        </div>

        <div *ngIf="visitorTypeDataList && visitorTypeDataList.length > 0" onDeleteSalesMode
            class="d-flex flex-column section-visitor-type mt-3 mb-4">
            <div class="d-flex flex-row visitor-type-label">
                <span>VISITOR TYPE</span>
                <img src="assets/images/icon-asterix.svg" style="width: 5px; align-self: start; margin-right: 5px;" />
                <i class="esb-icon-info info-icon pointer"
                    matTooltip="Select with the appropriate visitor type"
                    [matTooltipPosition]="'above'"></i>
            </div>
            <div *ngIf="currentBook.visitorTypeID" class="d-flex flex-row" style="padding: 20px 0px 10px 0;">
                <div class="d-flex flex-column justify-content-center align-items-center sales-mode-selected-info">
                    <span>{{ currentBook.visitorTypeName }}</span>
                    <button matRipple class="round-button delete-button pointer"
                        (click)="onDeleteVisitorType()">
                        <mat-icon class="ic-delete">close</mat-icon>
                    </button>
                </div>
            </div>
            <div class="visitor-type-content">
                <div *ngFor="let visitorType of visitorTypeDataList; let i=index;"
                    (click)="onClickVisitorType(visitorType)"
                    class="visitor-type-item pointer"
                    [ngClass]="selectedVisitorType == visitorType.visitorTypeID ? 'active': 'inactive'">
                    {{ visitorType.visitorTypeName }}
                </div>
            </div>
            <span *ngIf="visitorTypeDataList && visitorTypeDataList.length > 0 && !isVisitorTypeValidated" class="mat-error">You must select visitor type</span>
        </div>

        <div class="add-spacer"></div>

        <div class="d-flex flex-column section-table-management mt-3">
            <div class="d-flex flex-row flex-grow-1">
                <div class="d-flex flex-column flex-grow-1">
                    <div class="d-flex flex-row">
                        <span class="table-management-label">Select Table</span>
                    </div>
                    <span style="font-size: .9rem;">You may select more than one table</span>
                </div>
                <div *ngIf="!isUnavailableTable && isFullBooked && selectedSection" class="d-flex flex-row align-items-center p-2 full-book">
                    <mat-icon style="font-size: 1.3rem;">report_problem</mat-icon>
                    <span class="advance-status-text ml-2">Full Booked</span>
                </div>
                <div *ngIf="isUnavailableTable && selectedSection" class="d-flex flex-row align-items-center p-2 full-book">
                    <mat-icon style="font-size: 1.3rem;">report_problem</mat-icon>
                    <span class="advance-status-text ml-2">Unavailable Table</span>
                </div>
            </div>

            <div *ngIf="!selectedSection">
                <div class="unavailable-table-container d-flex align-items-center justify-content-center text-center">
                    <div class="container my-3">
                        <img src="assets/images/no-table.png" alt="no-table-image">
                        <p>Please activate table section in the table configuration</p>
                    </div>
                </div>
                <div class="add-spacer"></div>
            </div>

            <div *ngIf="selectedSection">
                <div class="d-flex flex-row table-color-notes mt-3">
                    <div class="d-flex flex-row flex-grow-1 align-items-center mt-3">
                        <div class="available-table"></div>
                        <span style="margin-right: 20px;">Available</span>
                        <div class="booked-table"></div>
                        <span style="margin-right: 20px;">Booked</span>
                        <div class="unavailable-table"></div>
                        <span>Unavailable</span>
                    </div>
                    <div matRipple class="d-flex flex-row table-detail-note mt-3 pointer"
                        (click)="onOpenTableDetails()">
                        <img src="assets/images/icon-table-detail-green.png" 
                            style="width: 30px; height: 15px; margin-right: 10px;" />
                        <span style="color: #2AA70F;">Table Details</span>
                    </div>
                </div>

                <div class="d-flex table-selected-list mt-3">
                    <div *ngIf="currentBook.bookTable.length == 0" >
                        <div class="d-flex flex-column justify-content-center align-items-center table-empty">
                            <img src="assets/images/icon-table-detail-white.png" 
                                style="width: 30px; height: 15px; margin-bottom: 5px;" />
                            <span>Select table below</span>
                        </div>
                    </div>
                    <div *ngIf="currentBook.bookTable.length > 0" class="d-flex flex-row">
                        <div *ngFor="let table of currentBook.bookTable; let i=index;"
                            class="d-flex flex-column justify-content-center align-items-center table-selected-info">
                            <span>{{ table.tableName }}</span>
                            <span>( {{ table.tableSeat }} PAX )</span>
                            <span>Min spend {{ table.tableMinimumBilling | number: '1.0' }}</span>
                            <button matRipple class="round-button delete-button pointer"
                                (click)="onDeleteTable(table)">
                                <mat-icon class="ic-delete">close</mat-icon>
                            </button>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center table-empty">
                            <img src="assets/images/icon-table-detail-white.png" 
                                style="width: 30px; height: 15px; margin-bottom: 5px;" />
                            <span>Select table below</span>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column table-layout">
                    <div class="d-flex flex-row align-items-center">
                        <div class="d-flex flex-grow-1">
                            <app-grid-pagination #tableSectionGrid
                                [items]="tableSectionDataList"
                                itemValue="tableSectionID"
                                itemText="tableSectionName"
                                [itemColumn]="5"
                                itemRow="1"
                                class="flex-grow-1"
                                [selectedItem]="selectedSection"
                                (clickItem)="onClickSection($event)">
                            </app-grid-pagination>
                        </div>
                        <div class="d-flex justify-content-end" style="padding-bottom: 10px;">
                            <app-grid-pager [for]="tableSectionGrid"></app-grid-pager>
                        </div>
                    </div>
                    
                    <app-table-map class="d-flex flex-grow-1"
                        [selectedSection]="selectedSection"
                        [viewMode]="false"
                        (clickTable)="onClickTable($event)">
                    </app-table-map>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-row add-booking-footer justify-content-end">
        <button mat-flat-button *ngIf="mode == 'add'"
            [routerLink]="['/book/booking-list']"
            class="btn-cancel">Cancel</button>
        <button mat-flat-button *ngIf="mode == 'edit'"
            [routerLink]="['/book/booking-list/booking-detail/', bookNumDisplay]"
            class="btn-cancel">Back</button>
        <button mat-flat-button
            (click)="onSave()"
            [disabled]="disableButton()"
            class="btn-submit">Submit Booking</button>
    </div>
</div>