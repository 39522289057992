import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { DialogData } from '../models/dialog.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  expandMenu$ = new BehaviorSubject<boolean>(null);
  isLoading$ = new BehaviorSubject<boolean>(null);

  constructor(public dialog: MatDialog) { }

  /**
   * Show alert box with Ok button.
   * Async process, stack with .then or await the process to get the user's action.
   * Disable close true to prevent user close the dialog by clicking outside of the dialog.
   * @param message string
   * @param disableClose boolean
   */
  async showAlert(title: string, message: string, disableClose: boolean = false) {
    const dialogRef = this.showDialog('alert', title, message, disableClose);

    return await dialogRef.afterClosed().toPromise();
  }

  /**
   * Show confirm box with Yes and No buttons.
   * Async process, stack with .then or await the process to get the user's action.
   * Disable close true to prevent user close the dialog by clicking outside of the dialog.
   * @param message string
   * @param disableClose boolean
   */
  async showConfirm(type:  'alert' | 'new-add' | 'confirm-add' | 'confirm-reject' | 'confirm-cancel', title: string, message: string, disableClose: boolean = false) {
    const dialogRef = this.showDialog(type, title, message, disableClose);

    return await dialogRef.afterClosed().toPromise();
  }

  private showDialog(type: 'alert' | 'new-add' | 'confirm-add' | 'confirm-reject' | 'confirm-cancel', title: string, message: string, disableClose: boolean = false) {
    const data: DialogData = {
      type,
      title,
      message
    };

    return this.dialog.open(ConfirmDialogComponent, {
      disableClose,
      data,
      autoFocus: false,
      panelClass: 'dialog-confirm'
    });
  }

  removeInvalidChars(str: string) {
    if (str) {
      str = str.replace(/(?:\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '')
               .replace(/[^A-Za-z0-9\w\.,\?""!@#\$%\^&\*\(\)\-_=\+;:<>\/\\\|\}\{\[\]`~\s\']*/g, '');
    }
    return str;
  }
}
