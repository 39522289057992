<div class="d-flex flex-column flex-grow-1 h-100">
  <div class="d-flex flex-row px-3 py-3">
    <div class="d-flex flex-grow-1 align-items-center">
      <img src="assets/images/esb-logo.png" class="app-logo" />
    </div>
    <div class="d-flex justify-content-center" style="cursor: pointer; width: fit-content;" 
      (click)="onClickMenu()">
      <button mat-button
        style="color: white; padding: 0 !important; min-width: 19px !important; outline: 0 !important;"
        class="h-100">
        <img src="assets/images/icon-burger-white.svg" class="image-responsive center-block" />
      </button>
    </div>
  </div>
  <div class="d-flex flex-column nav-profile p-2 mb-4">
    <div class="d-flex flex-column header">
      <img [src]="branchData.brand.brandLogo ? branchData.brand.brandLogo : 'assets/images/no-image.png'" [alt]="branchData.brand.brandName"
        class="brand-logo" />
    </div>
    <div class="d-flex align-items-center flex-column info">
      <span class="brand-name color-white">{{ branchData.brand.brandName }}</span>
      <span class="user-name color-white">{{ userData.fullName }}</span>
    </div>
  </div>
  <ul class="d-flex flex-column flex-grow-1">
    <li [matTooltip]="'Dashboard'"
      matTooltipPosition="right" 
      class="div-item-menu">
      <a [routerLink]="'/book/dashboard'"
        class="d-flex align-items-center item-menu"
        [ngClass]="{'expanded': expandMenu}"
        routerLinkActive="active">
        <i class="icon-esb-icon-dashboard pointer" style="font-size: 20px;"></i>
        <span style="margin-left: 15px;">{{ expandMenu ? 'Dashboard' : null }}</span>
      </a>
    </li>
    <li [matTooltip]="'Add Booking'"
      matTooltipPosition="right" 
      class="div-item-menu">
      <a [routerLink]="'/book/booking-list'"
        class="d-flex align-items-center item-menu"
        [ngClass]="{'expanded': expandMenu}"
        routerLinkActive="active">
        <i class="icon-esb-icon-add-booking left-menu-logo pointer"></i>
        <span style="margin-left: 10px;">{{ expandMenu ? 'Add Booking' : null }}</span>
      </a>
    </li>
    <li [matTooltip]="'Activity'"
      matTooltipPosition="right" 
      class="div-item-menu">
      <a [routerLink]="'/book/activity'"
        class="d-flex align-items-center item-menu"
        [ngClass]="{'expanded': expandMenu}"
        routerLinkActive="active">
        <i class="icon-esb-icon-activity left-menu-logo pointer"></i>
        <span style="margin-left: 10px;">{{ expandMenu ? 'Activity' : null }}</span>
      </a>
    </li>
  </ul>
  <div class="d-flex flex-grow-1"></div>
  <div class="d-flex left-menu-footer p-2 justify-content-center">
    <span style="margin-right: 10px;">PT ESENSI SOLUSI BUANA</span>
    <span>&copy;2021</span>
  </div>
</div>
