<div class="d-flex flex-column page-container p-4">
    <img src="assets/images/top-right-acc.svg" class="login-right-item" />
    <img src="assets/images/bottom-left-acc.svg" class="login-left-item" />
    <div class="d-flex justify-content-center align-items-center home-header">
        <img src="assets/images/esb-book-logo.svg" />
    </div>
    <div class="d-flex flex-row mt-5">
        <div class="carousel-card">
            <div class="container-fluid">
                <owl-carousel-o [options]="carouselOptions">
                    <ng-template carouselSlide [id]="1" class="item" >
                        <img src="assets/images/opening-1.gif" class="image-responsive center-block" 
                        style="max-height: 200px; object-fit: contain;" >
                        <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
                            <span style="text-align: center; font-size: 1.3rem; max-width: 500px;">
                                <b>Booking Made Easy</b>, only for you! Use ESB Book to book a table at your Favourite restaurant, anywhere, anytime.
                            </span>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide [id]="2" class="item" >
                        <img src="assets/images/opening-2.gif" class="image-responsive center-block" 
                        style="max-height: 200px; object-fit: contain;" >
                        <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
                            <span style="text-align: center; font-size: 1.3rem; max-width: 500px;">
                                <b>Equisite Dining</b>, made possible by ESB Book! Ensure a hassle-free eating experience through our platform.
                            </span>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide [id]="3" class="item" >
                        <img src="assets/images/opening-3.gif" class="image-responsive center-block" 
                        style="max-height: 200px; object-fit: contain;" >
                        <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
                            <span style="text-align: center; font-size: 1.3rem; max-width: 500px;">
                                <b>Share The Happiness!</b> Spread the warmth of  eating together with your beloved ones, starting from ESB Book.
                            </span>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
    <div class="d-flex flex-grow-1 justify-content-center home-content">
        <button mat-flat-button
            class="d-flex justify-content-center align-items-center button-start mt-5 pointer"
            (click)="onClickBook()">
            <span>Let's Book</span>
        </button>
    </div>
    <div class="d-flex justify-content-center">
        <div class="footer">
            *To continue, you agree to our <a [routerLink]="['../terms-condition']" target="_blank">Terms of Use</a> and to PT. Esensi Solusi Buana emails & updates and acknowledge that you read our <a [routerLink]="['../terms-condition']" target="_blank">Privacy Policy</a>.
        </div>
    </div>
</div>