<div class="d-flex flex-column overview-page">
    <div class="d-flex flex-row panel-header justify-content-center"
        [ngClass]="!expandMenu ? 'expand':''">
        <div class="d-flex flex-grow-1 flex-row align-items-center">
            <span class="day">{{ day }},</span>
            <span class="date">{{ date }}</span>
        </div>
        <div style="position: relative;">
            <input 
                [(ngModel)]="filterItems"
                (ngModelChange)="onChangeFilter()"
                type="text"
                class="filter-items"
                [ngClass]="{'expand': expandFilterItems, 'expand-items': expandFilterItems}"
                [readOnly]="!expandFilterItems"
                (click)="onOpenFilterItems()"
                placeholder="Search: Name, Booking Code, Phone" />

            <img src="assets/images/icon-search.png" 
                class="icon-search pointer" width="14" height="14"
                [ngClass]="expandFilterItems ? 'expand':'no-expand'"
                (click)="onClickFilterItems()" />

            <mat-icon matRipple class="icon-clear" 
                [ngClass]="expandFilterItems ? 'expand':'no-expand'"
                (click)="onClickFilterItems()">clear</mat-icon>
        </div>
            
        <div matRipple class="d-flex flex-row filter-status align-items-center mr-3 ml-3"
            (click)="onClickFilterStatus()">
            <img src="assets/images/icon-sort.png" class="mr-3" width="12" height="12" />
            <span class="mr-5">{{ filterStatus }}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </div>

        <div style="position: relative;">
            <input ngxDaterangepickerMd
              [(ngModel)]="filterDate"
              (ngModelChange)="onChangeDate()"
              [alwaysShowCalendars]="true"
              [ranges]="ranges"
              [autoApply]="false"
              type="text"
              class="filter-calendar"
              [readOnly]="true">

            <img src="assets/images/icon-calendar.png"
              class="icon-calendar ngx-daterangepicker-action" 
              (click)="openDatePicker($event)" />
            <mat-icon class="icon-calendar-arrow ngx-daterangepicker-action"
                (click)="openDatePicker($event)">keyboard_arrow_down</mat-icon>
        </div>
    </div>

    <div class="d-flex flex-column overview-content h-100">
        <div class="d-flex flex-row overview-summary">
            <div class="d-flex flex-grow-1 flex-column summary-item first">
                <span class="item-number status-confirmed">{{ totalAuthorized }}</span>
                <span class="item-text status-confirmed">Confirmed</span>
            </div>
            <div class="d-flex flex-grow-1 flex-column summary-item">
                <span class="item-number status-new-book">{{ totalNew }}</span>
                <span class="item-text status-new-book">New Booking</span>
            </div>
            <div class="d-flex flex-grow-1 flex-column summary-item">
                <span class="item-number">{{ totalCancelled }}</span>
                <span class="item-text">Cancelled</span>
            </div>
            <div class="d-flex flex-grow-1 flex-column summary-item">
                <span class="item-number status-rejected">{{ totalRejected }}</span>
                <span class="item-text status-rejected">Rejected</span>
            </div>
            <div class="d-flex flex-grow-1 flex-column summary-item last">
                <span class="item-number status-finished">{{ totalFinished }}</span>
                <span class="item-text status-finished">Finished</span>
            </div>
            <div class="d-flex flex-grow-1 flex-column summary-item last">
                <span class="item-number total-booking">{{ totalBooking }}</span>
                <span class="item-text total-booking">Total Booking</span>
            </div>
        </div>

        <div class="d-flex flex-row booking-gridview mt-2">
            <div *ngIf="dataSource.filteredData.length == 0"
                class="d-flex flex-column flex-grow-1 justify-content-center align-items-center section-no-data">
                <img src="assets/images/no-data-dashboard.png" class="no-data-img" />
                <span>No data found</span>
            </div>
            <div matRipple *ngFor="let bookHistory of (dataSource.filteredData | slice: lowValue : highValue);"
                class="d-flex flex-row grid-item"
                [ngClass]="{'expand': !expandMenu, 'new': toLowerCase(bookHistory.status.statusName) == 'new', 
                    'confirmed': toLowerCase(bookHistory.status.statusName) == 'confirmed',
                    'cancelled': toLowerCase(bookHistory.status.statusName) == 'cancelled',
                    'rejected': toLowerCase(bookHistory.status.statusName) == 'rejected',
                    'finished': toLowerCase(bookHistory.status.statusName) == 'finished'}"
                [routerLink]="['/book/booking-list/booking-detail/', bookHistory.bookNumDisplay]">
                <div class="d-flex flex-column flex-grow-1">
                    <div class="book-number">
                        {{ bookHistory.bookNumDisplay }}
                    </div>
                    <div class="book-customer">
                        {{ bookHistory.customerName }}
                    </div>
                    <div class="book-phone">
                        {{ bookHistory.phoneNumber }}
                    </div>
                    <div class="d-flex flex-row book-status">
                        <span [ngClass]="getStatusClass(bookHistory.status.statusName)"
                            style="margin-right: 10px;">
                            {{ getStatusNameValue(bookHistory.status.statusName) }}
                        </span>
                        <img [src]="getStatusIcon(bookHistory.status.statusName)" width="14" height="14" />
                    </div>
                </div>
                <div class="d-flex flex-column text-right">
                    <div class="d-flex flex-row book-branch">
                        <img src="assets/images/icon-home-black.png" width="14" height="14" />
                        <span style="margin-left: 5px;">{{ bookHistory.branch.branchName }}</span>
                    </div>
                    <div class="d-flex justify-content-end" style="margin-top: 15px;">
                        <span class="book-time">{{ bookHistory.bookTime }}</span>
                    </div>
                    <div class="d-flex flex-grow-1"></div>
                    <div class="d-flex flex-row align-items-center justify-content-end">
                        <span *ngIf="bookHistory.bookTable.length > 0" 
                            class="book-table">{{ getTableValue(bookHistory.bookTable) }}</span>
                        <span *ngIf="bookHistory.bookTable.length == 0" 
                            class="book-table">- </span>
                        <span class="book-pax"> / {{ bookHistory.paxTotal }} pax</span>
                        <div class="book-table-arrow">
                            <mat-icon class="icon-arrow-red">keyboard_arrow_right</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-grow-1"></div>
        
        <mat-paginator *ngIf="bookHistoryList?.length > 0" 
            class="overview-pagination"
            [length]="dataSource.filteredData.length"
            (page)="getPaginatorData($event)"
            [pageSizeOptions]="pageSizeOptions"
            pageSize=10
            showFirstLastButtons>
        </mat-paginator>
    </div>
</div>