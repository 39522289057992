<div class="d-flex flex-column p-2">
    <div class="d-flex flex-row dialog-header">
        <div class="d-flex flex-row flex-grow-1 align-items-end">
            <div>
                <img class="dialog-header-logo" src="assets/images/img-printer-qr.gif" />
            </div>
            <div class="d-flex flex-column">
                <span class="created-date">{{ createdDate }}</span>
                <div class="d-flex flex-row align-items-center">
                    <span class="customer-name">{{ bookHistory.customerName }} | </span>
                    <span class="branch-name">{{ bookHistory.branch.branchName }} </span>
                    <img style="width: 20px; height: 15px;" src="assets/images/icon-check.png" />
                </div>
            </div>
        </div>
        <div class="d-flex flex-row align-items-center">
            <span class="confirmed-text">Booking has been confirmed</span>
            <img style="height: 15px;" src="assets/images/icon-check-green.png" />
        </div>
    </div>
    <div class="dialog-content">
        <div class="d-flex flex-column section-content">
            <div class="d-flex flex-row w-100">
                <div class="d-flex flex-column book-number">
                    <span class="confirm-label">
                        Booking Code
                    </span>
                    <span class="confirm-content">
                        {{ bookHistory.bookNumDisplay }}
                    </span>
                </div>
                <div class="d-flex flex-column customer-phone">
                    <span class="confirm-label">
                        Phone Number
                    </span>
                    <span class="confirm-content">
                        {{ bookHistory.phoneNumber }}
                    </span>
                </div>
                <div class="d-flex flex-column book-date">
                    <span class="confirm-label">
                        Date and Time
                    </span>
                    <span class="confirm-content">
                        {{ bookDate }}
                    </span>
                    <span class="confirm-content">
                        {{ bookTime }}
                    </span>
                </div>
                <div class="d-flex flex-column book-method">
                    <span class="confirm-label">
                        Booking Method
                    </span>
                    <span class="confirm-content">
                        {{ bookHistory.bookChannel.bookChannelName }}
                    </span>
                </div>
            </div>
            <div class="d-flex flex-row w-100" style="margin-top: 15px;">
                <div class="d-flex flex-column customer-email">
                    <span class="confirm-label">
                        Email
                    </span>
                    <span class="confirm-content">
                        {{ bookHistory.email }}
                    </span>
                </div>
                <div class="d-flex flex-column book-status">
                    <span class="confirm-label">
                        Status
                    </span>
                    <span class="confirm-content">
                        {{ bookHistory.status.statusName }}
                    </span>
                </div>
                <div class="d-flex flex-column book-outlet-notes">
                    <span class="confirm-label">
                        Outlet Notes
                    </span>
                    <span class="confirm-content " style="max-width: 220px;">
                        {{ bookHistory.outletNotes }}
                    </span>
                </div>
                <div class="d-flex flex-column book-notes">
                    <span class="confirm-label">
                        Note
                    </span>
                    <span class="confirm-content">
                        {{ bookHistory.notes }}
                    </span>
                </div>
            </div>
        </div>
        <div class="d-flex flex-row section-content">
            <div class="d-flex flex-row w-100">
                <div class="d-flex flex-column sales-mode">
                    <span class="confirm-label">
                        Sales Mode
                    </span>
                    <span class="confirm-content">
                        {{ bookHistory.visitPurpose.visitPurposeName }}
                    </span>
                </div>
                <div class="d-flex flex-column visitor-type">
                    <span class="confirm-label">
                        Visitor Type
                    </span>
                    <span class="confirm-content">
                        {{ bookHistory.visitorType.visitorTypeName }}
                    </span>
                </div>
                <div class="d-flex flex-column book-table">
                    <span class="confirm-label">
                        Table
                    </span>
                    <span class="confirm-content">
                        {{ tables.toString() }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end dialog-footer">
        <button mat-raised-button
            (click)="onFinish()"
            class="btn-done">Done
        </button>
    </div>
</div>