<div *ngIf="!(company$ | async)"
  class="d-flex flex-column h-100 justify-content-center align-items-center loading-overlay">
    <img src="assets/images/top-right-acc.svg" class="login-right-item" />
    <img src="assets/images/esb-logo.gif" class="logo" />
    <div class="d-flex justify-content-center loading">
      <p>Please wait</p>
      <span><i></i><i></i></span>
    </div>
    <img src="assets/images/bottom-left-acc.svg" class="login-left-item" />
</div>
<router-outlet *ngIf="company$ | async"></router-outlet>