<div class="d-flex flex-column timeline-page">
    <div class="d-flex flex-row panel-header justify-content-center"
        [ngClass]="!expandMenu ? 'expand':''"
        style="z-index: 999;">
        <div class="d-flex flex-grow-1 flex-row align-items-center">
            <span class="day">{{ day }},</span>
            <span class="date">{{ date }}</span>
        </div>
        <div class="mr-3" style="position: relative;">
            <input 
                [(ngModel)]="filterItems"
                (ngModelChange)="onChangeFilter()"
                type="text"
                class="filter-items"
                [ngClass]="{'expand': expandFilterItems, 'expand-items': expandFilterItems}"
                [readOnly]="!expandFilterItems"
                (click)="onOpenFilterItems()"
                placeholder="Search: Name, Booking Code, Phone">

            <img src="assets/images/icon-search.png" 
                class="icon-search pointer" width="14" height="14"
                [ngClass]="expandFilterItems ? 'expand':'no-expand'"
                (click)="onClickFilterItems()" />

            <mat-icon matRipple class="icon-clear" 
                [ngClass]="expandFilterItems ? 'expand':'no-expand'"
                (click)="onClickFilterItems()">clear</mat-icon>
        </div>
        <div style="position: relative;">
            <input ngxDaterangepickerMd
              [(ngModel)]="filterDate"
              (ngModelChange)="onChangeDate()"
              [alwaysShowCalendars]="true"
              [singleDatePicker]="true"
              [autoApply]="true"
              type="text"
              class="filter-calendar"
              [readOnly]="true">

            <img src="assets/images/icon-calendar.png"
                class="icon-calendar ngx-daterangepicker-action" 
                (click)="openDatePicker($event)" />
            <mat-icon class="icon-calendar-arrow ngx-daterangepicker-action"
                (click)="openDatePicker($event)">keyboard_arrow_down</mat-icon>
        </div>
    </div>

    <div class="timeline-content">
        <div class="table-wrapper">
            <table class="time-table">
                <thead class="table-head-timetable">
                    <tr class="row-head">
                        <th>
                            <span class="booking-summary">Booking Timeline</span>
                            <p class="booked-total">({{ tableBookedTotal }} Tables Booked)</p>
                        </th>
                        <th class="table-head-time" *ngFor="let time of timeDataArray; let i=index;">
                            {{ time }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let table of tableDataArray; let i=index;"
                        class="table-list-section">
                        <th class="table-detail">
                            <span class="table-name">{{ table.tableName }}</span><br>
                            <span class="table-pax">Total Pax: {{ table.paxTotal }}</span><br>
                            <span class="table-amount">Min amount: {{ table.minAmount | number: '1.0' }}</span>
                        </th>
                        <td *ngFor="let time of timeDataArray; let j=index;"
                            [id]="table.tableName+'-'+time"
                            style="width: 75px; height: 75px; min-width: 75px; min-height: 75px;"></td>
                    </tr>
                </tbody>
            </table>
            <button *ngFor="let table of timeTableDataArray; let i=index;"
                [id]="'id'+table.tableName+'-'+table.bookTime" 
                class="timetable-button"
                [ngClass]="getStatusClassButton(table.status)">
                <img class="icon-time-content" src="assets/images/icon-timeline-green.png" width="14" height="14" />
                <span class="table-book-time">{{ table.bookTime }} - {{ table.bookEndTime }}</span>
                <div class="d-flex flex-column tooltip-time">
                    <div class="d-flex flex-column tooltip-status">
                        <span class="status-label">Status:</span>
                        <div class="d-flex flex-row align-items-center">
                            <span class="status-content"
                                [ngClass]="getStatusClass(table.status)">{{ table.status }}</span>
                            <img [src]="getStatusIcon(table.status)" width="12" height="12" />
                        </div>
                    </div>
                    <div class="d-flex flex-column tooltip-status">
                        <span class="status-label">Branch:</span>
                        <span class="branch-content">{{ table.branch }}</span>
                    </div>
                    <div class="d-flex flex-column tooltip-status">
                        <span class="status-label">Customer Name:</span>
                        <span class="branch-content">{{ table.customer }}</span>
                    </div>
                </div>
            </button>

        </div>
    </div>
</div>