import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { BookHistory } from 'src/app/models/book-history.model';
import { DialogDataBookHistoryList } from 'src/app/models/dialog.model';

@Component({
  selector: 'app-calendar-detail',
  templateUrl: './calendar-detail.component.html',
  styleUrls: ['./calendar-detail.component.scss']
})
export class CalendarDetailComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  bookHistoryList: BookHistory[];
  bookTables: string[];
  dataSource = new MatTableDataSource<BookHistory>();
  highValue: number = 4;
  lowValue: number = 0;
  pageSizeOptions: number[];
  selectedDate: string;
  totalBooking = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataBookHistoryList,
    public dialogRef: MatDialogRef <CalendarDetailComponent>,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.bookHistoryList = this.data.bookHistory;
    this.dataSource = new MatTableDataSource<BookHistory>(this.bookHistoryList);
    this.dataSource.paginator = this.paginator;
    this.selectedDate = formatDate(this.data.selectedDate, 'd MMMM yyyy', 'en-US');
    this.totalBooking = this.bookHistoryList.length;
    this.pageSizeOptions = [4];
  }

  // used to build an array of papers relevant at any given time
  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  getStatusNameValue(status: string) {
    let result = status;
    if (result == 'New') {
      result = 'New Book';
    }
    return result;
  }

  getStatusClass(status: string) {
    let className = '';
    if (status == 'Confirmed') {
      className = 'status-confirmed';
    } else if (status == 'Finished') {
      className = 'status-finished';
    } else if (status == 'New') {
      className = 'status-new';
    } else if (status == 'Cancelled') {
      className = 'status-cancelled';
    } else if (status == 'Rejected') {
      className = 'status-rejected';
    }

    return className;
  }

  getStatusIcon(status: string) {
    let icon = '';
    if (status == 'Confirmed') {
      icon = 'assets/images/icon-check-green.png';
    } else if (status == 'Finished') {
      icon = 'assets/images/icon-check-purple.png';
    } else if (status == 'New') {
      icon = 'assets/images/icon-check-blue.png';
    } else if (status == 'Cancelled') {
      icon = 'assets/images/icon-check-grey.png';
    } else if (status == 'Rejected') {
      icon = 'assets/images/icon-check-red.png';
    }

    return icon;
  }

  getTableValue(table: string[]) {
    this.bookTables = [];
    table.forEach(
      (item: any) => {
        this.bookTables.push(item.tableName);
      }
    );
    return this.bookTables.toString();
  }

  onClose() {
    this.dialogRef.close();
  }

}
