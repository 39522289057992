<section class="app-header" [ngClass]="!expandMenu ? 'expand':''">
    <app-header></app-header>
    <div class="d-flex flex-row booking-list-header"
        [ngClass]="!expandMenu ? 'expand':''">
        <div class="d-flex flex-grow-1 align-items-center">
            <span class="header-text">Booking List</span>
        </div>
        <div matRipple 
            [matMenuTriggerFor]="branchList"
            class="d-flex flex-row branch-list justify-content-center align-items-center">
            <img src="assets/images/icon-home-black.png" width="18" height="18" />
            <span class="branch-name ml-2 mr-3">{{ branchData.branchName }}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
        <mat-menu #branchList="matMenu" yPosition="above">
            <div class="search-input-div">
                <div style="position: relative;" (click)="$event.stopPropagation()">
                    <input #txtKeyword
                        [(ngModel)]="searchInput"
                        class="search-input-branch"
                        placeholder ="Search Branch"
                        (keyup)="onSearchBranch($event.target.value)"
                        (click)="$event.stopPropagation()"
                        (blur)="onBlur()" />
                    <mat-icon matRipple class="icon-clear-search-booking pointer" 
                        (click)="onResetFilterInput()">clear</mat-icon>
                </div>
            </div>
            <div *ngFor="let branch of selectedBranchDataList">
                <button mat-menu-item 
                    [ngClass]="branch.branchID == branchData.branchID ? 'active-item':''"
                    class="btn-branch"
                    (click)="onClickBranch(branch)">{{ branch.branchName }}</button>
            </div>
        </mat-menu>
    </div>
</section>

<div class="d-flex flex-column booking-list-page" [ngClass]="!expandMenu ? 'expand':''">
    <div class="d-flex flex-row panel-header justify-content-center"
        [ngClass]="!expandMenu ? 'expand':''">
        <div class="d-flex flex-grow-1 align-items-center">
            <span>Booking List</span>
        </div>
        <div style="position: relative;">
            <input 
                [(ngModel)]="filterItems"
                (ngModelChange)="onChangeFilter()"
                type="text"
                class="filter-items"
                [ngClass]="{'expand': expandFilterItems, 'expand-items': expandFilterItems}"
                [readOnly]="!expandFilterItems"
                (click)="onOpenFilterItems()"
                placeholder="Search: Name, Booking Code, Phone" />

            <img src="assets/images/icon-search.png" 
                class="icon-search pointer" width="14" height="14"
                [ngClass]="expandFilterItems ? 'expand':'no-expand'"
                (click)="onClickFilterItems()" />

            <mat-icon matRipple class="icon-clear" 
                [ngClass]="expandFilterItems ? 'expand':'no-expand'"
                (click)="onClickFilterItems()">clear</mat-icon>
        </div>
            
        <div matRipple class="d-flex flex-row filter-status align-items-center mr-3 ml-3"
            (click)="onClickFilterStatus()">
            <img src="assets/images/icon-sort.png" class="mr-3" width="12" height="12" />
            <span class="mr-5">{{ filterStatus }}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </div>

        <div style="position: relative;">
            <input ngxDaterangepickerMd
              [(ngModel)]="filterDate"
              (ngModelChange)="onChangeDate()"
              [alwaysShowCalendars]="true"
              [ranges]="ranges"
              [autoApply]="false"
              type="text"
              class="filter-calendar"
              [readOnly]="true">

            <img src="assets/images/icon-calendar.png"
              class="icon-calendar ngx-daterangepicker-action" 
              (click)="openDatePicker($event)" />
            <mat-icon class="icon-calendar-arrow ngx-daterangepicker-action"
                (click)="openDatePicker($event)">keyboard_arrow_down</mat-icon>
        </div>

        <button mat-flat-button 
            class="btn-add-book"
            [routerLink]="'/book/booking-list/add-booking'">
            <i class="fa fa-plus mr-2"></i> Add Booking
        </button>
    </div>

    <div class="spacer" style="position: fixed; margin-top: 74px;"></div>
    
    <div class="table-container">
        <div *ngIf="dataSource.filteredData.length == 0"
            class="d-flex flex-column flex-grow-1 justify-content-center align-items-center section-no-data h-100">
            <img src="assets/images/no-data-book.png" class="no-data-img" />
            <span>No data found</span>
        </div>
        <table *ngIf="dataSource.filteredData.length > 0"
            mat-table [dataSource]="dataSource"
            class="w-100 data-table">
            <ng-container matColumnDef="bookNum">
                <th mat-header-cell
                    *matHeaderCellDef>
                    BOOKING CODE
                </th>
                <td mat-cell
                    *matCellDef="let row">
                    {{ row.bookNumDisplay }}
                </td>
            </ng-container>
            <ng-container matColumnDef="customerName">
                <th mat-header-cell
                    *matHeaderCellDef>
                    CUSTOMER NAME
                </th>
                <td mat-cell
                    *matCellDef="let row">
                    {{ row.customerName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell
                    *matHeaderCellDef>
                    PHONE NUMBER
                </th>
                <td mat-cell
                    *matCellDef="let row">
                    {{ row.phoneNumber }}
                </td>
            </ng-container>
            <ng-container matColumnDef="bookDate">
                <th mat-header-cell
                    *matHeaderCellDef>
                    DATE & TIME
                </th>
                <td mat-cell
                    *matCellDef="let row">
                    <span style="font-weight: 600;">{{ row.bookDate | date:'d MMMM y' }} |</span> {{ row.bookTime }}
                </td>
            </ng-container>
            <ng-container matColumnDef="table">
                <th mat-header-cell
                    *matHeaderCellDef>
                    TABLE
                </th>
                <td mat-cell
                    *matCellDef="let row"
                    [ngClass]="">
                    {{ getTableValue(row.bookTable) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell
                    *matHeaderCellDef>
                    BOOKING STATUS
                </th>
                <td mat-cell
                    *matCellDef="let row"
                    [ngClass]="getStatusClass(row.status.statusName)">
                    <div class="d-flex flex-row">
                        <div class="d-flex flex-grow-1 align-items-center">
                            <span class="mr-2">{{ getStatusNameValue(row.status.statusName) }}</span>
                            <img [src]="getStatusIcon(row.status.statusName)" width="14" height="14" />
                        </div>
                        <div class="d-flex align-items-center justify-content-end">
                            <img src='assets/images/icon-next-arrow.png' width="7" height="12" />
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row
                *matHeaderRowDef="['bookNum', 'customerName', 'phoneNumber', 'bookDate', 'table', 'status']; sticky: true">
            </tr>
            <tr mat-row
                *matRowDef="let row; columns: ['bookNum', 'customerName', 'phoneNumber', 'bookDate', 'table', 'status']"
                [routerLink]="['/book/booking-list/booking-detail/', row.bookNumDisplay]">
            </tr>
        </table>
    </div>

    <div class="d-flex flex-grow-1"></div>
    
    <mat-paginator style="padding: 0 10px 0 10px;" 
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons>
    </mat-paginator>
</div>