import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, filter, share, tap } from 'rxjs/operators';

import { AuthService } from './services/auth.service';
import { HttpService } from './services/http.service';
import { UiService } from './services/ui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'esb-book';
  company$: Observable<string>;

  constructor(
    private authService: AuthService,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.route.queryParams.pipe(
      tap((params: Params) => {
        this.authService.checkReferrer(params['referrer']);
      }),
      tap((params: Params) => {
        this.company$ = this.authService.checkSession(params['session']).pipe(
          filter(data => data !== null),
          concatMap(() => this.http.getCompanyToken()),
          catchError((error) => {
            if (error.status === 401) {
              this.uiService.showAlert('Warning', 'Please do not sign in on other device', false).then(
                () => window.location.href = this.http.getReferrer()
              );
            }
            return of(null);
          }),
          share()
        );
      }),
      tap((params: Params) => {
        if (params['referrer'] || params['session']) {
          this.router.navigate(['.'], { relativeTo: this.route });
          this.uiService.expandMenu$.next(true);
        }
      })
    ).subscribe();
  }
}
