import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid-base',
  templateUrl: './grid-base.component.html',
  styleUrls: ['./grid-base.component.css']
})
export class GridBaseComponent implements OnInit {
  pageNumber = 0;
  maxPage = 0;

  constructor() { }

  ngOnInit() {
  }

  filterItem() {
  }

  goFirstPage() {
    this.pageNumber = 0;
    this.filterItem();
  }

  prev() {
    if (this.pageNumber > 0) {
      this.pageNumber--;
      this.filterItem();
    }
  }

  next() {
    if (this.pageNumber < this.maxPage - 1) {
      this.pageNumber++;
      this.filterItem();
    }
  }

}
