<div class="d-flex flex-column h-100">
  <div *ngIf="display === 'vertical'"
    class="d-flex justify-content-end">
    <label class="control-label">
      Page {{ for?.pageNumber + 1 }} of {{ for?.maxPage === 0 ? 1 : for?.maxPage }}
    </label>
  </div>
  <div class="d-flex flex-grow-1">
    <button mat-flat-button
      class="page-prev"
      (click)="for?.prev()">
      <i class="glyphicon glyphicon-arrow-left"></i>
    </button>
    <div *ngIf="display === 'horizontal'"
      class="d-flex justify-content-center align-items-center"
      style="min-width: 60px;">
      {{ for?.pageNumber + 1 }} of {{ for?.maxPage === 0 ? 1 : for?.maxPage }}
    </div>
    <span *ngIf="display === 'vertical'">&nbsp;&nbsp;</span>
    <button mat-flat-button
      class="page-next"
      (click)="for?.next()">
      <i class="glyphicon glyphicon-arrow-right"></i>
    </button>
  </div>
</div>
