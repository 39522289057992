<div id="reject-form" class="d-flex flex-column reject-dialog-body">
    <div class="reject-dialog-header justify-content-center align-items-center">
        <img class="img-reject" src="assets/images/book-reject.png" />
    </div>
    <div class="d-flex flex-column modal-body justify-content-center">
        <div class="dialog-title">
            Reject This Booking?
        </div>
        <div class="dialog-message">
            A Rejection email will be automatically sent to customer.
        </div>
        <div class="reject-notes">
            <div class="d-flex title-notes align-items-center">
                <span>Reason for Rejection</span>
                <img src="assets/images/icon-asterix.svg" style="width: 5px; margin-right: 5px; align-self: start;" />
            </div>
            <div class="content-notes">
                <form [formGroup]="form">
                    <mat-form-field appearance="standard" 
                        [hideRequiredMarker]="hideRequiredControl.value"
                        [floatLabel]="floatLabelControl.value">
                        <textarea matInput
                            formControlName="rejectNotes"
                            rows="5"
                            maxlength="250"
                            placeholder="Enter your reason for rejection the booking"
                            class="form-control reject-notes-content">
                        </textarea>
                        <mat-error *ngIf="isFieldValid('rejectNotes')">{{getNotesError()}}</mat-error>
                    </mat-form-field>
                </form>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center" 
        style="padding: 0 1rem 0 1rem;">
        <button mat-flat-button
            class="btn-cancel w-100"
            mat-dialog-close>
            Close
        </button>
        <button mat-flat-button
            class="btn-reject w-100"
            (click)="onReject()">
            Reject Booking
        </button>
    </div>

</div>