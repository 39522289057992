import { Component, OnInit, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UiService } from 'src/app/services/ui.service';
import { Branch } from 'src/app/models/branch.model';
import { BranchDataService } from 'src/app/services/branch-data.service';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  branchData: Branch;
  date: string;
  expandMenu: boolean;
  time: string;
  userData: User;

  constructor(private authService: AuthService,
    private branchDataService: BranchDataService,
    private router: Router,
    private uiService: UiService) { }

  ngOnInit() {
    timer(0, 1000).pipe(takeUntil(this.onDestroy$)).subscribe(
      () => this.time = formatDate(new Date(), '| HH:mm:ss', 'en-US')
    );

    this.date = formatDate(new Date(), 'd MMMM yyyy', 'en-US');

    this.uiService.expandMenu$.subscribe(
      (data: boolean) => {
        this.expandMenu = data;
      }
    );

    this.branchDataService.activeBranchData$.subscribe(
      (data: Branch) => {
        this.branchData = data;
      }
    );

    this.userData = this.authService.loggedInUser$.value;
  }

  onClickMenu() {
    this.expandMenu = !this.expandMenu;
    this.uiService.expandMenu$.next(this.expandMenu);
  }

  enableTimer() {
    setTimeout(() => {
      this.enableTimer();
    }, 1000);
  }

  getClassTime() {
    let className = '';
    let month = formatDate(new Date(), 'MMMM', 'en-US');
    let lengthMonth = month.length;
    if (lengthMonth <= 4) {
      className = 'w-1';
    } else if (lengthMonth == 5) {
      className = 'w-2';
    } else if (lengthMonth >= 7) {
      className = 'w-3';
    }

    return className;
  }

  onSignOut() {
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
