import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { BookHistory } from 'src/app/models/book-history.model';
import { DialogDataTableDetails } from 'src/app/models/dialog.model';
import { AddBookService } from 'src/app/services/add-book.service';

@Component({
  selector: 'app-table-details',
  templateUrl: './table-details.component.html',
  styleUrls: ['./table-details.component.scss']
})
export class TableDetailsComponent implements OnInit {
  bookHistoryList: BookHistory[];
  tableDataArray: any;
  timeTableDataArray: any;
  timeDataArray: any;

  constructor(public dialogRef: MatDialogRef <TableDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataTableDetails,
    private addBookService: AddBookService) { }

  ngOnInit() {
    this.initData();
    this.fetchBookingData();
  }

  initData() {
    //Init timer array list data
    var arr = [], i, j;
    for(i=0; i<24; i++) {
      for(j=0; j<2; j++) {
        arr.push(i + ":" + (j===0 ? "00" : 30*j) );
      }
    }

    var pos = arr.indexOf("0:00");
    this.timeDataArray = arr.slice(pos).concat(arr.slice(0,pos));
  }

  fetchBookingData() {
    const momentVar = moment(this.data.bookDate, 'DD-MM-YYYY');
    const bookDate = momentVar.format("DD-MM-YYYY");
    this.addBookService.fetchBookHistory(this.data.branchID, bookDate, bookDate).subscribe(
      (bookHistory: BookHistory[]) => {    
        this.bookHistoryList = bookHistory;
        if (this.bookHistoryList.length > 0) {
          this.tableDataArray = this.addBookService.getTableListAsArray(this.bookHistoryList, 'table');
          this.timeTableDataArray = this.addBookService.getTableListAsArray(this.bookHistoryList, 'timetable');
          setTimeout(() => {
            this.initTimeTableContent();
          }, 0);
        }
      }
    );
  }

  getStatusClass(status: string) {
    let className = '';
    if (status == 'Confirmed') {
      className = 'status-confirmed';
    } else if (status == 'Finished') {
      className = 'status-finished';
    } else if (status == 'New') {
      className = 'status-new';
    } else if (status == 'Cancelled') {
      className = 'status-cancelled';
    } else if (status == 'Rejected') {
      className = 'status-rejected';
    }

    return className;
  }

  getStatusIcon(status: string) {
    let icon = '';
    if (status == 'Confirmed') {
      icon = 'assets/images/icon-check-green.png';
    } else if (status == 'Finished') {
      icon = 'assets/images/icon-check-purple.png';
    } else if (status == 'New') {
      icon = 'assets/images/icon-check-blue.png';
    } else if (status == 'Cancelled') {
      icon = 'assets/images/icon-check-grey.png';
    } else if (status == 'Rejected') {
      icon = 'assets/images/icon-check-red.png';
    }

    return icon;
  }

  getStatusClassButton(status: string) {
    let className = '';
    if (status == 'Confirmed') {
      className = 'div-status-confirmed';
    } else if (status == 'Finished') {
      className = 'div-status-finished';
    } else if (status == 'New') {
      className = 'div-status-new';
    } else if (status == 'Cancelled') {
      className = 'div-status-cancelled';
    } else if (status == 'Rejected') {
      className = 'div-status-rejected';
    }

    return className;
  }

  onClose() {
    this.dialogRef.close();
  }

  setTimeTable(bookID: any, cellID: any, bookTime: string, bookEndTime: string, index: number = -1) {
    let bookTimeArr = bookTime.split(':');
    let bookTimeHour = +bookTimeArr[0] * 60;
    let bookTimeMinute = +bookTimeArr[1] * 1;
    let bookTimeTotalInMinutes = +bookTimeHour + +bookTimeMinute;

    let bookEndTimeArr = bookEndTime.split(':');
    let bookEndTimeHour = +bookEndTimeArr[0] * 60;
    let bookEndTimeMinute = +bookEndTimeArr[1] * 1;
    let bookEndTimeTotalInMinutes = +bookEndTimeHour + +bookEndTimeMinute;

    let bookTimeGap = bookEndTimeTotalInMinutes - bookTimeTotalInMinutes;
    let length = bookTimeGap/30;

    const element = document.getElementById(cellID);
    if (element) {
      const leftPosition = element.offsetLeft + 32.5;
      const topPosition = element.offsetTop + 22.5;
      document.getElementById(bookID).style.left = leftPosition + 'px';
      document.getElementById(bookID).style.top = topPosition + 'px';
      document.getElementById(bookID).style.width = 75 * length + 'px';
      if (index == 0) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  initTimeTableContent() {
    let i = 0;
    this.timeTableDataArray.forEach(table => {
      const bookID = 'id'+table.tableName+'-'+table.bookTime;
      const cellID = table.tableName+'-'+table.bookTime;
      this.setTimeTable(bookID, cellID, table.bookTime, table.bookEndTime, i);
      i += 1;
    });
  }

}
