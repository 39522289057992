import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Branch } from 'src/app/models/branch.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BranchDataService } from 'src/app/services/branch-data.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit, OnDestroy {
  @Output() clickMenu = new EventEmitter;
  onDestroy$ = new Subject<void>();
  branchData: Branch;
  expandMenu: boolean;
  userData: User;

  constructor(private authService: AuthService,
    private branchDataService: BranchDataService,
    private uiService: UiService) { }

  ngOnInit() {
    this.uiService.expandMenu$.subscribe(
      (data: boolean) => {
        this.expandMenu = data;
      });

      this.branchDataService.activeBranchData$.subscribe(
        (data: Branch) => {
          this.branchData = data;
        }
      );

      this.userData = this.authService.loggedInUser$.value;
  }

  onClickMenu() {
    this.expandMenu = !this.expandMenu;
    this.uiService.expandMenu$.next(this.expandMenu);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
