import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AddBook, ReturnMessage, UpdateStatusBook } from 'src/app/models/add-book.model';

import { BookHistory } from 'src/app/models/book-history.model';
import { Branch } from 'src/app/models/branch.model';
import { SalesMode } from 'src/app/models/sales-mode.model';
import { Table, TableSection } from 'src/app/models/table.model';
import { VisitorType } from 'src/app/models/visitor-type.model';

import { AddBookService } from 'src/app/services/add-book.service';
import { BranchDataService } from 'src/app/services/branch-data.service';
import { UiService } from 'src/app/services/ui.service';

import { TableDetailsComponent } from 'src/app/shared/table-details/table-details.component';
import { BookingConfirmationComponent } from '../../add-booking/booking-confirmation/booking-confirmation.component';
import { CancelBookingComponent } from './cancel-booking/cancel-booking.component';
import { RejectBookingComponent } from './reject-booking/reject-booking.component';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.scss']
})
export class BookingDetailComponent implements OnInit {
  onDestroy$ = new Subject<void>();
  bookDate: string;
  bookTime: string;
  branchID: number;
  bookingDetail: BookHistory;
  bookNumDisplay: string;
  branchData: Branch;
  currentBook: AddBook;
  expandMenu: boolean;
  isFullBooked = true;
  isUnavailableTable: boolean;
  isSalesModeValidated = false;
  isVisitorTypeValidated = false;
  salesModeDataList: SalesMode[];
  selectedSalesMode: number;
  selectedSection: TableSection;
  selectedTableList: Table[];
  selectedVisitorType: number;
  showStatusConfirmedInfo: boolean;
  showStatusNewInfo: boolean;
  tableSectionDataList: TableSection[];
  visitorTypeDataList: VisitorType[];
  viewMode: boolean;

  constructor(private addBookService: AddBookService,
    private branchDataService: BranchDataService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private uiService: UiService) { }

  ngOnInit() {
    this.uiService.expandMenu$.subscribe(
      (data: boolean) => {
        this.expandMenu = data;
      }
    );

    this.branchDataService.activeBranchData$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: Branch) => {
        if (data) {
          this.branchData = data;
          this.initSalesModeList();
        }
      }
    );

    this.branchDataService.tableSection$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: TableSection[]) => {
        if (data) {
          this.tableSectionDataList = data;
          this.onClickSection(this.tableSectionDataList[0]);
        }
      }
    );

    // Booking Number onInit
    this.initData();

    this.addBookService.addBook$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: AddBook) => {
        this.currentBook = data;
      }
    );

    this.branchDataService.fetchVisitorTypeData(this.branchData.branchID).subscribe(
      (data: VisitorType[]) => {
        if(data){
          this.visitorTypeDataList = data;
        }
      }
    );
  }

  initData() {
    this.bookNumDisplay = this.route.snapshot.params['booknumdisplay'];
    this.addBookService.fetchBookDetail(this.branchData.branchID, this.bookNumDisplay).pipe(
      takeUntil(this.onDestroy$)
    ).pipe(
      map((data: BookHistory) => {
        if(!isEmpty(data)){
          this.bookingDetail = data[0];
          this.bookDate = this.bookingDetail.bookDate;
          this.bookTime = this.bookingDetail.bookTime;
          this.branchID = this.bookingDetail.branch.branchID;
          this.selectedSalesMode = this.bookingDetail.visitPurpose.visitPurposeID;
          this.selectedVisitorType = this.bookingDetail.visitorType.visitorTypeID;
          this.isSalesModeValidated = this.selectedSalesMode ? true : false;
          this.isVisitorTypeValidated = this.selectedVisitorType ? true : false;
  
          if (this.branchID && this.bookDate && this.bookTime) {
            this.getTableList(this.branchID, this.bookDate, this.bookTime);
            this.addBookService.getBook(this.bookingDetail);
          }
          
          this.showStatusConfirmedInfo = this.bookingDetail.status.statusID == 8 || this.bookingDetail.status.statusID == 43 ? true : false;
          this.showStatusNewInfo = this.bookingDetail.status.statusID == 1 ? true : false;
  
          if (this.showStatusConfirmedInfo || this.bookingDetail.status.statusID == 12) {
            this.viewMode = true;
          } else if (this.showStatusNewInfo) {
            this.viewMode = false;
          }
        } else {
          this.router.navigate(['book/dashboard/overview']);
        }
      })
    ).subscribe();
  }

  initSalesModeList() {
    this.branchDataService.fetchSalesModeData(this.branchData.branchID).pipe(
      takeUntil(this.onDestroy$)
    ).pipe(
      map((data: SalesMode[]) => {
        this.salesModeDataList = data;
      })
    )
    .subscribe();
  }

  onClickSalesMode(salesMode: SalesMode) {
    const currentBook = this.currentBook;
    
    currentBook.visitPurposeID = salesMode.visitPurposeID;
    currentBook.visitPurposeName = salesMode.visitPurposeName;
    this.selectedSalesMode = salesMode.visitPurposeID;
    this.isSalesModeValidated = true;
  }

  onClickVisitorType(visitorType: VisitorType) {
    const currentBook = this.currentBook;

    currentBook.visitorTypeID = visitorType.visitorTypeID;
    currentBook.visitorTypeName = visitorType.visitorTypeName;
    this.selectedVisitorType = visitorType.visitorTypeID;
    this.isVisitorTypeValidated = true;
  }

  onClickSection(item: TableSection) {
    this.selectedSection = this.tableSectionDataList.find(table => table.tableSectionID === item.tableSectionID);
    let countTableSectionAvailibility = 0;
    let countTableAvailibility = 0;
    for (const tableSection of this.tableSectionDataList) {
      if (tableSection) {
        const tableSectionStatusAvailable = tableSection.tableSectionStatus == 'available';
        if (tableSectionStatusAvailable) {
          countTableSectionAvailibility += 1;
        }
        const tableStatusAvailable = tableSection.table.find(table => table.tableStatusClass == 'available');
        if (tableStatusAvailable) {
          countTableAvailibility = 1;
        }
      }
    }

    if (countTableSectionAvailibility > 0 && countTableAvailibility == 0) {
      this.isFullBooked = true;
      this.isUnavailableTable = false;
    } else if (countTableSectionAvailibility > 0 && countTableAvailibility > 0) {
      this.isFullBooked = false;
      this.isUnavailableTable = false;
    } else if (countTableSectionAvailibility == 0) {
      this.isFullBooked = false;
      this.isUnavailableTable = true;
    }
  }

  onClickTable(item: Table) {
    this.addBookService.addBook(item);
    for (const tables of this.selectedSection.table) {
      if (item.tableID == tables.tableID) {
        tables.tableStatusClass = 'book';
      }
    }
  }

  onDeleteTable(item: Table) {
    this.addBookService.deleteTable(item);
    for (const tableSection of this.tableSectionDataList) {
      for (const table of tableSection.table) {
        if (item.tableID == table.tableID) {
          table.tableStatusClass = 'available';
        }
      }
    }
  }

  getTableList(branchID: number, bookDate: string, bookTime: string) {
    this.branchDataService.fetchTableListData(branchID, bookDate, bookTime).subscribe();
  }

  onReactivate() {
    this.spinner.show();
    const reactivateBookData: UpdateStatusBook = {
      bookNum: this.bookingDetail.bookNum,
      statusID: 1
    };

    this.addBookService.updateStatusBook(reactivateBookData).subscribe(
      (data: ReturnMessage) => {
        if (data.success) {
          this.spinner.hide();
          this.router.navigate(['/book/booking-list']);
        } else {
          this.uiService.showAlert(null, data.message);
          this.spinner.hide();
        }
      },
      (error: HttpErrorResponse) => {
        let errMsg = 'Failed connect to server. Please Try Again.';
        if (error.error.message) {
          errMsg = error.error.message;
        }
        this.uiService.showAlert(null, errMsg);
        this.spinner.hide();
      }
    );
  }

  onCancel() {
    const dialogRef = this.dialog.open(CancelBookingComponent, {
      data: {
        bookHistory: this.bookingDetail
      },
      disableClose: true,
      id: 'CancelBookingComponent',
      autoFocus: false,
      panelClass: 'cancel-dialog'
    });

    dialogRef.afterClosed().subscribe(
      (data: boolean) => {
        if (data) {
          this.initData();
        }
      }
    );
  }

  onReject() {
    const dialogRef = this.dialog.open(RejectBookingComponent, {
      data: {
        bookHistory: this.bookingDetail
      },
      disableClose: true,
      id: 'RejectBookingComponent',
      autoFocus: false,
      panelClass: 'reject-dialog'
    });

    dialogRef.afterClosed().subscribe(
      (data: boolean) => {
        if (data) {
          this.initData();
        }
      }
    );
  }

  onDeleteSalesMode() {
    const bookData = this.addBookService.addBook$.value;
    
    bookData.visitPurposeID = 0;
    bookData.visitPurposeName = '';
    this.selectedSalesMode = 0;
  }

  onDeleteVisitorType() {
    const bookData = this.addBookService.addBook$.value;
    
    bookData.visitorTypeID = 0;
    bookData.visitorTypeName = '';
    this.selectedVisitorType = 0;
  }

  onOpenTableDetails() {
    const formattedDate = this.bookDate;
    const momentDate = new Date(formattedDate);
    const bookDate = moment(momentDate).format("DD-MM-YYYY");

    this.dialog.open(TableDetailsComponent, {
      data: {
        branchID: this.branchID,
        bookDate: bookDate
      },
      disableClose: true,
      id: 'TableDetailsComponent',
      autoFocus: false,
      panelClass: 'table-details'
    });
  }

  async onSave() {
    const confirm = await this.uiService.showConfirm('confirm-add', 'Add and Confirm this booking?',
        "A confirmation email will be automatically sent to customer's email", true);

    if (confirm) {
      this.spinner.show();
      const bookData = this.currentBook;
      this.addBookService.bookTable(bookData).subscribe(
        (data: ReturnMessage) => {
          if (data.success) {
            this.addBookService.fetchBookHistory(bookData.branchID, bookData.bookDate, bookData.bookDate).pipe(
              map(() => {
                this.spinner.hide();
                const bookHistory = this.addBookService.getBookHistoryByBookNum(data.bookNum);
                this.dialog.open(BookingConfirmationComponent, {
                  data: {
                    bookHistory
                  },
                  disableClose: true,
                  id: 'BookingConfirmationComponent',
                  autoFocus: false,
                  panelClass: 'dialog-confirmation'
                });
              })
            ).subscribe();
          } else {
            this.uiService.showAlert(null, data.message);
            this.spinner.hide();
          }
        },
        (error: HttpErrorResponse) => {
          let errMsg = 'Failed connect to server. Please Try Again.';
          if (error.error.message) {
            errMsg = error.error.message;
          }
          this.uiService.showAlert(null, errMsg);
          this.spinner.hide();
        }
      );
    }
      
  }

  disableReactivateButton() {
    if (this.bookingDetail.status.statusID != 2) {
      return true;
    } else {
      return false;
    }    
  }

  disableCancelButton() {
    if (this.showStatusNewInfo || this.bookingDetail.status.statusID != 43) {
      return true;
    } else {
      return false;
    }    
  }

  disableRejectButton() {
    if (this.showStatusConfirmedInfo || this.bookingDetail.status.statusID != 1) {
      return true;
    } else {
      return false;
    }    
  }

  disableSubmitButton() {
    if (!this.isSalesModeValidated || (this.visitorTypeDataList.length > 0 && !this.isVisitorTypeValidated) || 
      this.currentBook.bookTable.length == 0 || this.bookingDetail.status.statusID != 1) {
      return true;
    } else {
      return false;
    }    
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
