import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Branch } from 'src/app/models/branch.model';
import { BranchDataService } from 'src/app/services/branch-data.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('txtKeyword') txtKeyword: ElementRef;
  onDestroy$ = new Subject<void>();
  branchData: Branch;
  branchDataList: Branch[];
  expandMenu: boolean;
  links = [
    {
      link: '/book/dashboard/overview',
      label: 'Overview'
    },
    {
      link: '/book/dashboard/calendar',
      label: 'Calendar'
    },
    {
      link: '/book/dashboard/timeline',
      label: 'Timeline'
    }
  ];
  activeLink: string;
  searchInput: string;
  selectedBranchDataList: Branch[];

  constructor(private branchDataService: BranchDataService,
    private router: Router,
    private uiService: UiService) { }

  ngOnInit() {
    const route = this.router.url.split('?')[0];
    if (route == '/book/dashboard') {
      this.activeLink = this.links[0].link;
    } else {
      this.activeLink = route;
    }

    this.uiService.expandMenu$.subscribe(
      (data: boolean) => {
        this.expandMenu = data;
      }
    );

    this.branchDataService.activeBranchData$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: Branch) => {
        if (data) {
          this.branchData = data;
        }
      }
    );

    this.branchDataList = this.branchDataService.branchData$.value;
    this.selectedBranchDataList = this.branchDataList;
  }

  onClickBranch(branch: Branch) {
    this.branchDataService.setActiveBranch(branch);
    this.branchDataService.fetchSalesModeData(branch.branchID).subscribe();
  }

  onSearchBranch(value: string) {
    if (value) {
      this.selectedBranchDataList = this.search(value);
      this.txtKeyword.nativeElement.focus();
    } else {
      this.selectedBranchDataList = this.branchDataList;
      this.txtKeyword.nativeElement.focus();
    }
  }

  onResetFilter() {
    this.searchInput = '';
    this.selectedBranchDataList = this.branchDataList;
  }

  search(value: string) { 
    let filter = value.toLowerCase();
    return this.branchDataList.filter(option => option.branchName.toLowerCase().includes(filter));
  }

  onBlur() {
    this.txtKeyword.nativeElement.focus();
  }

}
