<section class="app-header" [ngClass]="!expandMenu ? 'expand':''">
    <app-header></app-header>
    <div class="d-flex flex-row booking-detail-header"
        [ngClass]="!expandMenu ? 'expand':''">
        <div class="d-flex flex-grow-1">
            <span class="header-text">Booking List</span>
        </div>
        <div class="d-flex flex-row branch-detail justify-content-center align-items-center">
            <img src="assets/images/icon-home-black.png" width="14" height="14" />
            <span class="branch-name ml-2 mr-3">{{ branchData.branchName }}</span>
        </div>
    </div>
</section>

<div *ngIf="bookingDetail" class="d-flex flex-column booking-detail-page" [ngClass]="!expandMenu ? 'expand':''">
    <div class="d-flex flex-row panel-header-booking-detail justify-content-center"
        [ngClass]="!expandMenu ? 'expand':''">
        <div class="d-flex flex-grow-1 flex-row">
            <div class="d-flex flex-grow-1 flex-row align-items-center">
                <span [routerLink]="'/book/booking-list'" 
                    class="bootstrap-link">Booking List
                </span>
                <mat-icon>keyboard_arrow_right</mat-icon>
                <span style="margin-left: 10px;">Booking Detail</span>
            </div>
            <div class="d-flex flex-row align-items-center">
                <button mat-flat-button
                    [ngClass]="disableReactivateButton() ? 'disabled': ''"
                    (click)="onReactivate()"
                    [disabled]="disableReactivateButton()"
                    class="btn-reactivate mr-3">Reactivate</button>
                <button mat-flat-button
                    [ngClass]="disableCancelButton() ? 'disabled': ''"
                    (click)="onCancel()"
                    [disabled]="disableCancelButton()"
                    class="btn-cancel mr-3">Cancel Book</button>
                <button mat-flat-button
                    [ngClass]="disableRejectButton() ? 'disabled': ''"
                    (click)="onReject()"
                    [disabled]="disableRejectButton()"
                    class="btn-reject">Reject Book</button>
            </div>
        </div>
    </div>

    <div *ngIf="showStatusConfirmedInfo"
        class="d-flex align-items-center book-info-section">
        <div class="d-flex flex-grow-1 align-items-center justify-content-center book-info-confirmed">
            <div class="d-flex align-items-center flex-grow-1 icon-text-alert">
                <img src="assets/images/icon-alert-success.png" 
                    style="width: 25px; margin-right: 10px; align-self: start;" />
                <span class="book-info-confirm-text">Booking has been confirmed and all data has been sent to POS.</span>
            </div>
            <div class="d-flex align-items-center flex-grow-1 justify-content-end confirm-date-book">
                <span style="margin-right: 4px;">Confirm date</span>
                <span>{{ bookingDetail.editedDate | date:'d MMMM y HH:mm' }}</span>
            </div>
        </div>
    </div>

    <div *ngIf="bookingDetail.status.statusID == 2"
        class="d-flex align-items-center book-info-section">
        <div class="d-flex flex-grow-1 align-items-center justify-content-center book-info">
            <div class="d-flex align-items-center flex-grow-1 icon-text-alert">
                <img src="assets/images/icon-alert.png" 
                    style="width: 25px; margin-right: 10px; align-self: start;" />
                <span class="book-rejected-text">Booking has been rejected at </span>
                <span class="book-rejected-date">{{ bookingDetail.editedDate | date:'d MMMM y HH:mm' }}</span>
            </div>
        </div>
    </div>

    <div *ngIf="bookingDetail.status.statusID == 12"
        class="d-flex align-items-center book-info-section">
        <div class="d-flex flex-grow-1 align-items-center justify-content-center book-info">
            <div class="d-flex align-items-center flex-grow-1 icon-text-alert">
                <img src="assets/images/icon-alert.png" 
                    style="width: 25px; margin-right: 10px; align-self: start;" />
                <span class="book-rejected-text">Booking has been cancelled at </span>
                <span class="book-rejected-date">{{ bookingDetail.editedDate | date:'d MMMM y HH:mm' }}</span>
            </div>
        </div>
    </div>

    <div *ngIf="!(salesModeDataList && salesModeDataList.length > 0) && selectedSection"
        class="d-flex align-items-center book-info-section">
        <div class="d-flex flex-grow-1 align-items-center justify-content-center book-info">
            <div class="d-flex align-items-center flex-grow-1 icon-text-alert">
                <img src="assets/images/icon-warning.png" 
                    style="width: 25px; margin-right: 10px; align-self: start;" />
                <span class="book-info-text">Please activate visit purpose in the branch configuration</span>
            </div>
        </div>
    </div>

    <div *ngIf="!selectedSection && (salesModeDataList && salesModeDataList.length > 0)"
        class="d-flex align-items-center book-info-section">
        <div class="d-flex flex-grow-1 align-items-center justify-content-center book-info">
            <div class="d-flex align-items-center flex-grow-1 icon-text-alert">
                <img src="assets/images/icon-warning.png" 
                    style="width: 25px; margin-right: 10px; align-self: start;" />
                <span class="book-info-text">Please activate table section in the table configuration</span>
            </div>
        </div>
    </div>

    <div *ngIf="!selectedSection && !(salesModeDataList && salesModeDataList.length > 0)"
        class="d-flex align-items-center book-info-section">
        <div class="d-flex flex-grow-1 align-items-center justify-content-center book-info">
            <div class="d-flex align-items-center flex-grow-1 icon-text-alert">
                <img src="assets/images/icon-warning.png" 
                    style="width: 25px; margin-right: 10px; align-self: start;" />
                <span class="book-info-text">Please activate visit purpose in the branch configuration and table section in the table configuration</span>
            </div>
        </div>
    </div>

    <div *ngIf="!showStatusConfirmedInfo && bookingDetail.status.statusID == 1 && salesModeDataList && salesModeDataList.length > 0 && selectedSection" class="spacer"></div>

    <div class="d-flex flex-column booking-detail-content">
        <div class="d-flex flex-row" style="width: 100%;">
            <div class="d-flex flex-column" style="width: 50%; padding-right: 15px;">
                <div class="d-flex flex-column item-div">
                    <div class="item-label">
                        Booking Code
                    </div>
                    <div class="item-content">
                        {{ bookingDetail.bookNumDisplay }}
                    </div>
                </div>
                <div class="d-flex flex-column item-div">
                    <div class="item-label">
                        Customer Name
                    </div>
                    <div class="item-content">
                        {{ bookingDetail.customerName }}
                    </div>
                </div>
                <div class="d-flex flex-column item-div">
                    <div class="item-label">
                        Phone Number
                    </div>
                    <div class="item-content">
                        {{ bookingDetail.phoneNumber }}
                    </div>
                </div>
                <div class="d-flex flex-column item-div">
                    <div class="item-label">
                        Booking Date & Time
                    </div>
                    <div class="item-content">
                        {{ bookingDetail.bookDate | date:'d MMMM y' }} | {{ bookingDetail.bookTime }}
                    </div>
                </div>
                <div class="d-flex flex-column item-div">
                    <div class="item-label">
                        Pax
                    </div>
                    <div class="item-content">
                        {{ bookingDetail.paxTotal }} Pax
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row" style="width: 50%; padding-right: 5px;">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-column item-div">
                        <div class="item-label">
                            Booking Method
                        </div>
                        <div class="item-content">
                            {{ bookingDetail.bookChannel.bookChannelName }}
                        </div>
                    </div>
                    <div class="d-flex flex-column item-div">
                        <div class="item-label">
                            Email
                        </div>
                        <div class="item-content">
                            {{ bookingDetail.email }}
                        </div>
                    </div>
                    <div class="d-flex flex-column item-div">
                        <div class="item-label">
                            Status
                        </div>
                        <div class="item-content">
                            {{ bookingDetail.status.statusName }}
                        </div>
                    </div>
                    <div class="d-flex flex-column item-div">
                        <div class="item-label">
                            Branch
                        </div>
                        <div class="item-content">
                            {{ bookingDetail.branch.branchName }}
                        </div>
                    </div>
                    <div class="d-flex flex-column item-div ">
                        <div class="item-label">
                            Outlet Notes
                        </div>
                        <div *ngIf="(bookingDetail.outletNotes && bookingDetail.outletNotes.trim() !== '') ? true : false; else noOutletNotes" class="item-content" style="max-width: 500px">
                            {{ bookingDetail.outletNotes }}
                        </div>
                        <ng-template #noOutletNotes>-</ng-template>
                    </div>    
                    <div class="d-flex flex-column item-div">
                        <div class="item-label">
                            Notes
                        </div>
                        <div class="item-content">
                            {{ bookingDetail.notes }}
                        </div>
                    </div>
                    <div *ngIf="bookingDetail.status.statusID == 2" class="d-flex flex-column item-div">
                        <div class="item-label">
                            Reason For Rejection
                        </div>
                        <div class="item-content">
                            {{ bookingDetail.reason }}
                        </div>
                    </div>
                    <div *ngIf="bookingDetail.status.statusID == 12" class="d-flex flex-column item-div">
                        <div class="item-label">
                            Reason For Cancellation
                        </div>
                        <div class="item-content">
                            {{ bookingDetail.reason }}
                        </div>
                    </div>
                </div>
                <div *ngIf="!showStatusConfirmedInfo && bookingDetail.status.statusID == 1 && salesModeDataList && salesModeDataList.length > 0 && selectedSection" class="d-flex flex-grow-1 justify-content-end div-edit-booking">
                    <div matRipple class="pointer" 
                        style="height: fit-content;"
                        [routerLink]="['/book/booking-list/edit-booking/', bookNumDisplay]">
                        <span class="btn-edit-booking pointer">Edit Booking</span>
                        <img src="assets/images/icon-edit-book.png" 
                            style="width: 15px; margin-left: 10px; align-self: start; cursor: pointer;" />
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!showStatusNewInfo"  class="spacer mt-2"></div>
    </div>

    <div *ngIf="salesModeDataList && salesModeDataList.length > 0 && selectedSection">
        <div *ngIf="showStatusNewInfo" 
            class="d-flex align-items-center book-info-section">
            <div class="d-flex flex-grow-1 align-items-center justify-content-center book-info">
                <div class="d-flex align-items-center flex-grow-1 icon-text-alert">
                    <img src="assets/images/icon-alert.png" 
                        style="width: 25px; margin-right: 10px; align-self: start;" />
                    <span class="book-info-text">This booking has not been confirmed. Please complete the data below to confirm.</span>
                </div>
                <div class="d-flex align-items-center flex-grow-1 justify-content-end created-date-book">
                    <span style="margin-right: 4px;">Created date</span>
                    <span>{{ bookingDetail.createdDate | date:'d MMMM y HH:mm' }}</span>
                </div>
            </div>
        </div>

        <div *ngIf="salesModeDataList && salesModeDataList.length > 0" 
            class="d-flex flex-column section-sales-mode mt-2">
            <div class="d-flex flex-row sales-mode-label">
                <span>SALES MODE (VISIT PURPOSE)</span>
                <img src="assets/images/icon-asterix.svg" style="width: 5px; align-self: start; margin-right: 5px;" />
                <i class="esb-icon-info info-icon pointer"
                    matTooltip="Select with the appropriate transaction method"
                    [matTooltipPosition]="'above'"></i>
            </div>
            <div *ngIf="currentBook.visitPurposeID" 
                class="d-flex flex-row" 
                style="padding: 20px 0px 10px 0;">
                <div class="d-flex flex-column justify-content-center align-items-center sales-mode-selected-info">
                    <span>{{ currentBook.visitPurposeName }}</span>
                    <button *ngIf="showStatusNewInfo" matRipple class="round-button delete-button pointer"
                        (click)="onDeleteSalesMode()">
                        <mat-icon class="ic-delete">close</mat-icon>
                    </button>
                </div>
            </div>
            <div *ngIf="showStatusNewInfo" class="sales-mode-content">
                <div *ngFor="let salesMode of salesModeDataList; let i=index;"
                    (click)="onClickSalesMode(salesMode)"
                    class="sales-mode-item pointer"
                    [ngClass]="selectedSalesMode == salesMode.visitPurposeID ? 'active': 'inactive'">
                    {{ salesMode.visitPurposeName }}
                </div>
            </div>
            <span *ngIf="!isSalesModeValidated && showStatusNewInfo" class="mat-error">You must select sales mode</span>
        </div>

        <div *ngIf="visitorTypeDataList && visitorTypeDataList.length > 0" 
            class="d-flex flex-column section-visitor-type mt-2">
            <div class="d-flex flex-row visitor-type-label">
                <span>VISITOR TYPE</span>
                <img src="assets/images/icon-asterix.svg" style="width: 5px; align-self: start; margin-right: 5px;" />
                <i class="esb-icon-info info-icon pointer"
                    matTooltip="Select with the appropriate visitor type"
                    [matTooltipPosition]="'above'"></i>
            </div>
            <div *ngIf="currentBook.visitorTypeID" class="d-flex flex-row" style="padding: 20px 0px 10px 0;">
                <div class="d-flex flex-column justify-content-center align-items-center sales-mode-selected-info">
                    <span>{{ currentBook.visitorTypeName }}</span>
                    <button *ngIf="showStatusNewInfo" matRipple class="round-button delete-button pointer"
                        (click)="onDeleteVisitorType()">
                        <mat-icon class="ic-delete">close</mat-icon>
                    </button>
                </div>
            </div>
            <div *ngIf="showStatusNewInfo" class="visitor-type-content">
                <div *ngFor="let visitorType of visitorTypeDataList; let i=index;"
                    (click)="onClickVisitorType(visitorType)"
                    class="visitor-type-item pointer"
                    [ngClass]="selectedVisitorType == visitorType.visitorTypeID ? 'active': 'inactive'">
                    {{ visitorType.visitorTypeName }}
                </div>
            </div>
            <span *ngIf="!isVisitorTypeValidated && showStatusNewInfo" class="mat-error">You must select visitor type</span>

            <div class="spacer mt-2"></div>
        </div>

        <div *ngIf="selectedSection" class="d-flex flex-column section-table-management mt-2">
            <div class="d-flex flex-row flex-grow-1">
                <div class="d-flex flex-column flex-grow-1">
                    <div class="d-flex flex-row">
                        <span class="table-management-label">Select Table</span>
                    </div>
                    <span style="font-size: .8rem;">You may select more than one table</span>
                </div>
                <div *ngIf="!isUnavailableTable && isFullBooked" class="d-flex flex-row align-items-center p-2 full-book">
                    <mat-icon style="font-size: 1.3rem;">report_problem</mat-icon>
                    <span class="advance-status-text ml-2">Full Booked</span>
                </div>
                <div *ngIf="isUnavailableTable" class="d-flex flex-row align-items-center p-2 full-book">
                    <mat-icon style="font-size: 1.3rem;">report_problem</mat-icon>
                    <span class="advance-status-text ml-2">Unavailable Table</span>
                </div>
            </div>
            <div class="d-flex flex-row table-color-notes mt-3">
                <div class="d-flex flex-row flex-grow-1 align-items-center mt-3">
                    <div class="available-table"></div>
                    <span style="margin-right: 20px;">Available</span>
                    <div class="booked-table"></div>
                    <span style="margin-right: 20px;">Booked</span>
                    <div class="unavailable-table"></div>
                    <span>Unavailable</span>
                </div>
                <div matRipple class="d-flex flex-row table-detail-note mt-3 pointer"
                    (click)="onOpenTableDetails()">
                    <img src="assets/images/icon-table-detail-green.png" 
                        style="width: 30px; height: 15px; margin-right: 10px;" />
                    <span style="color: #2AA70F;">Table Details</span>
                </div>
            </div>
            <div class="d-flex table-selected-list mt-3">
                <div *ngIf="currentBook.bookTable.length == 0" >
                    <div class="d-flex flex-column justify-content-center align-items-center table-empty">
                        <img src="assets/images/icon-table-detail-white.png" 
                            style="width: 30px; height: 15px; margin-bottom: 5px;" />
                        <span>Select table below</span>
                    </div>
                </div>
                <div *ngIf="currentBook.bookTable.length > 0" class="d-flex flex-row">
                    <div *ngFor="let table of currentBook.bookTable; let i=index;"
                        class="d-flex flex-column justify-content-center align-items-center table-selected-info">
                        <span>{{ table.tableName }}</span>
                        <span>( {{ table.tableSeat }} PAX )</span>
                        <span>Min spend {{ table.tableMinimumBilling | number: '1.0' }}</span>
                        <button matRipple *ngIf="showStatusNewInfo" class="round-button delete-button pointer"
                            (click)="onDeleteTable(table)">
                            <mat-icon class="ic-delete">close</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="showStatusNewInfo" 
                        class="d-flex flex-column justify-content-center align-items-center table-empty">
                        <img src="assets/images/icon-table-detail-white.png" 
                            style="width: 30px; height: 15px; margin-bottom: 5px;" />
                        <span>Select table below</span>
                    </div>
                </div>
            </div>

            <div class="spacer mt-2"></div>

            <div class="d-flex flex-column table-layout">
                <div class="d-flex flex-row align-items-center">
                    <div class="d-flex flex-grow-1">
                        <app-grid-pagination #tableSectionGrid
                            [items]="tableSectionDataList"
                            itemValue="tableSectionID"
                            itemText="tableSectionName"
                            [itemColumn]="5"
                            itemRow="1"
                            class="flex-grow-1"
                            [selectedItem]="selectedSection"
                            (clickItem)="onClickSection($event)">
                        </app-grid-pagination>
                    </div>
                    <div class="d-flex justify-content-end" style="padding-bottom: 10px;">
                        <app-grid-pager [for]="tableSectionGrid"></app-grid-pager>
                    </div>
                </div>
                <app-table-map class="d-flex flex-grow-1"
                    [selectedSection]="selectedSection"
                    [viewMode]="viewMode"
                    (clickTable)="onClickTable($event)">
                </app-table-map>
            </div>
        </div>
        <div class="d-flex flex-row booking-detail-footer justify-content-end">
            <button mat-raised-button
                [routerLink]="'/book/booking-list'"
                class="btn-cancel-submit">Back</button>
            <button mat-raised-button
                (click)="onSave()"
                [disabled]="disableSubmitButton()"
                class="btn-submit">Submit Booking</button>
        </div>
    </div>
    
</div>