import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { Branch } from 'src/app/models/branch.model';
import { BranchDataService } from 'src/app/services/branch-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  branchData: Branch;
  isLoading: boolean = true;

  constructor(
    private branchDataService: BranchDataService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.branchDataService.fetchBranchData().pipe(
      tap((branchData) => {
        if (!isEmpty(branchData)) {
          const branchString = this.branchDataService.loadLocalStorage('branch');
          let activeBranch: Branch = null;
          if (branchString) {
            let branchJson = JSON.parse(branchString);
            activeBranch = branchData.find(bd => bd.branchID == branchJson['branchID']) ? branchData.find(bd => bd.branchID == branchJson['branchID']) : branchData[0];
          } else {
            activeBranch = branchData[0];
          }
          this.branchDataService.activeBranchData$.next(activeBranch);
          this.router.navigate(['book/dashboard/overview']);
        } else {
          this.isLoading = false;
        }
      })
    ).subscribe();
  }

}
