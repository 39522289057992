import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { HttpService } from './http.service';
import { map, take, tap } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { AddBook, BookTable, ReturnMessage, UpdateStatusBook } from '../models/add-book.model';
import { Table } from '../models/table.model';
import * as _ from 'lodash';
import { BookHistory, BookHistoryStatusLog } from '../models/book-history.model';

@Injectable({
  providedIn: 'root'
})
export class AddBookService {
  addBook$ = new BehaviorSubject<AddBook>(null);
  bookCalendar$ = new BehaviorSubject<BookHistory[]>(null);
  bookHistory$ = new BehaviorSubject<BookHistory[]>(null);
  bookHistoryStatusLog$ = new BehaviorSubject<BookHistoryStatusLog[]>(null);

  constructor(private http: HttpService) { }

  /**
   * Init book data.
   */
  initBook() {
    const newBook: AddBook = {
      bookNum: '',
      bookChannelID: 0,
      branchID: 0,
      title: '',
      customerName: '',
      email: '',
      phoneNumber: '',
      paxTotal: 0,
      bookDate: '',
      bookTime: '',
      outletNotes: '',
      notes: '',
      visitPurposeID: 0,
      visitPurposeName: '',
      visitorTypeID: 0,
      visitorTypeName: '',
      bookTable: []
    };

    this.addBook$.next(newBook);
  }

  /**
   * Add Table.
   */
  addBook(table: Table) {
    const addBookingData = <AddBook>this.addBook$.value;
    const bookTables = <BookTable[]>addBookingData.bookTable;
    const existingTable = bookTables.find(bt => {
      return bt.tableID === table.tableID;
    });

    if (!existingTable) {
      if (table) {
        const newTable = {
          tableID: table.tableID,
          tableName: table.tableName,
          tableSeat: table.tableSeat,
          tableMinimumBilling: table.tableMinimumBilling
        };
        bookTables.push(newTable);
      }
    }

    this.addBook$.next({
      ...this.addBook$.value,
      bookTable: _.cloneDeep(bookTables)
    });
  }

  /**
   * Get book data.
   */
   getBook(bookHistory: BookHistory) {
    const bookData: AddBook = {
      bookNum: bookHistory.bookNum,
      bookChannelID: bookHistory.bookChannel.bookChannelID,
      branchID: bookHistory.branch.branchID,
      title: bookHistory.title,
      customerName: bookHistory.customerName,
      email: bookHistory.email,
      phoneNumber: bookHistory.phoneNumber,
      paxTotal: bookHistory.paxTotal,
      bookDate: bookHistory.bookDate,
      bookTime: bookHistory.bookTime,
      outletNotes: bookHistory.outletNotes,
      notes: bookHistory.notes,
      visitPurposeID: bookHistory.visitPurpose.visitPurposeID,
      visitPurposeName: bookHistory.visitPurpose.visitPurposeName,
      visitorTypeID: bookHistory.visitorType.visitorTypeID,
      visitorTypeName: bookHistory.visitorType.visitorTypeName,
      bookTable: bookHistory.bookTable
    };

    this.addBook$.next(bookData);
  }

  /**
   * Delete Table.
   */
  deleteTable(table: Table) {
    const addBookingData = <AddBook>this.addBook$.value;
    const bookTables = <Table[]>addBookingData.bookTable;
    const newTables = bookTables.filter(bt => {
      return bt.tableID !== table.tableID;
    });

    if (newTables) {
      this.addBook$.next({
        ...this.addBook$.value,
        bookTable: _.cloneDeep(newTables)
      });
    }
  }

  deleteAllTable() {
    const bookTables = [];
    this.addBook$.next({
      ...this.addBook$.value,
      bookTable: _.cloneDeep(bookTables)
    });
  }

  /**
   * Book a table.
   * Will refetch the table list.
   * @param bookData BookData
   */
  bookTable(bookData: AddBook) {
    return this.http.privatePost<null>(AppSettings.postBookUrl, bookData);
  }

  /**
   * Update status booking.
   * @param bookData UpdateStatusBook
   */
  updateStatusBook(bookData: UpdateStatusBook) {
    return this.http.privatePost<null>(AppSettings.updateStatusUrl, bookData);
  }

  /**
   * Get book history.
   * Will refetch the book history list.
   * @param branchID number
   * @param bookDateStart string
   * @param bookDateEnd string
   * @param filterByDay boolean
   */
  fetchBookHistory(branchID: number, bookDateStart: string, bookDateEnd: string, filterByDay: boolean = false) {
    return this.http.privatePost<BookHistory[]>(AppSettings.getBookHistoryUrl, {
      branchID: branchID,
      bookDateStart: bookDateStart,
      bookDateEnd: bookDateEnd
    }).pipe(
      map((data: BookHistory[]) => {
        if (!filterByDay) {
          this.bookHistory$.next(data);
        }

        return data;
      })
    );
  }

  /**
   * Get book history for dashboard only.
   * @param branchID number
   * @param bookDateStart string
   * @param bookDateEnd string
   */
  fetchBookCalendar(branchID: number, bookDateStart: string, bookDateEnd: string) {
    return this.http.privatePost<BookHistory[]>(AppSettings.getBookCalendarUrl, {
      branchID: branchID,
      bookDateStart: bookDateStart,
      bookDateEnd: bookDateEnd
    }).pipe(
      map((data: BookHistory[]) => {
        this.bookCalendar$.next(data);
        return data;
      })
    );
  }

  /**
   * Get book detail.
   * @param branchID number
   * @param bookNumDisplay string
   */
  fetchBookDetail(branchID: number, bookNumDisplay: string) {
    return this.http.privatePost<BookHistory>(AppSettings.getBookHistoryUrl, {
      branchID: branchID,
      bookNumDisplay: bookNumDisplay
    }).pipe(
      map((data: BookHistory) => {
        return data;
      })
    );
  }

  /**
   * Get book history status log.
   * @param bookNum string
   */
   fetchBookHistoryStatusLog(bookNum: string) {
    return this.http.privatePost<BookHistoryStatusLog[]>(AppSettings.getBookHistoryStatusUrl, {
      bookNum: bookNum
    }).pipe(
      map((data: BookHistoryStatusLog[]) => {
        this.bookHistoryStatusLog$.next(data);
        return data;
      })
    );
  }

  /**
   * Find book history by Book Number.
   * @param bookNum string
   */
  getBookHistoryByBookNum(bookNum: string): BookHistory {
    return _.cloneDeep(this.bookHistory$.value.find((bh) => bh.bookNum === bookNum));
  }

  /**
   * Get booking summary.
   */
  getBookingSummary() {
    const bookHistory = <BookHistory[]>_.cloneDeep(this.bookHistory$.value);
    let authorizedTotal = 0;
    let cancelledTotal = 0;
    let newTotal = 0;
    let rejectedTotal = 0;
    let finishedTotal = 0;
    let totalBooking = 0;

    bookHistory.forEach(bh => {
      if (bh.status.statusID === 1) {
        newTotal += 1;
      }
      if (bh.status.statusID === 2) {
        rejectedTotal += 1;
      }
      if (bh.status.statusID === 43 || bh.status.statusID === 35) {
        authorizedTotal += 1;
      }
      if (bh.status.statusID === 12) {
        cancelledTotal += 1;
      }
      if (bh.status.statusID === 8) {
        finishedTotal += 1;
      }
    });

    totalBooking = authorizedTotal + cancelledTotal + newTotal + rejectedTotal + finishedTotal;
    let result = [
      {
        status: 'Confirmed',
        total: authorizedTotal
      },
      {
        status: 'Cancelled',
        total: cancelledTotal
      },
      {
        status: 'New',
        total: newTotal
      },
      {
        status: 'Rejected',
        total: rejectedTotal
      },
      {
        status: 'Finished',
        total: finishedTotal
      },
      {
        status: 'Total',
        total: totalBooking
      },
    ];

    return result;
  }

  /**
   * Get table data list.
   */
  getTableListAsArray(bookHistory: BookHistory[], mode: string) {
    let result = [];
    bookHistory.forEach(
      (data: BookHistory) => {
        if (data.bookTable.length > 0) {
          data.bookTable.forEach(
            (table: BookTable) => {
              if (mode == 'table') {
                const tableName = result.find(t => t.tableName == table.tableName);
                if (!tableName) {
                  let bookData = {
                    'tableID': table.tableID,
                    'tableName': table.tableName,
                    'minAmount': table.tableMinimumBilling ? table.tableMinimumBilling : 0,
                    'paxTotal': data.paxTotal,
                    'bookTime': data.bookTime,
                    'bookEndTime': data.bookEndTime,
                    'status': data.status.statusName,
                    'branch': data.branch.branchName,
                    'customer': data.customerName
                  };
                  
                  result.push(bookData);
                }
              } else if (mode == 'timetable') {
                let bookData = {
                  'tableID': table.tableID,
                  'tableName': table.tableName,
                  'minAmount': table.tableMinimumBilling ? table.tableMinimumBilling : 0,
                  'paxTotal': data.paxTotal,
                  'bookTime': data.bookTime,
                  'bookEndTime': data.bookEndTime,
                  'status': data.status.statusName,
                  'branch': data.branch.branchName,
                  'customer': data.customerName
                };
                
                result.push(bookData);
              }
            }
          );
        }
      }
    );

    return result;
  }
}
