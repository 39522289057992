import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  carouselOptions: any;

  constructor(private router: Router) { }

  ngOnInit() {
    this.carouselOptions = {
      items: 1, 
      dots: true, 
      dotClass: 'owl-dot', 
      nav: true, 
      navText: ["<img src='assets/images/icon-prev-arrow.png'>","<img src='assets/images/icon-next-arrow.png'>"], 
      loop: true, 
      autoplay: true,
      autoplaySpeed: 200,
      margin: 2
    };
  }

  onClickBook() {
    this.router.navigate(['book/dashboard/overview']);
  }

}
