export class AppSettings {
  public static getCompanyAuthUrl = 'erp/company-auth';
  public static getBookChannelUrl = 'erp/esb-book/get-booking-channel';
  public static getBookCalendarUrl = 'erp/esb-book/get-book-calendar';
  public static getBookHistoryUrl = 'erp/esb-book/get-book-history';
  public static getBookHistoryStatusUrl = 'erp/esb-book/get-book-history-status';
  public static getBranchUrl = 'erp/esb-book/get-branch';
  public static getSalesMode = 'erp/esb-book/get-visit-purpose';
  public static getTableSectionList = 'erp/esb-book/get-table-section';
  public static getVisitorType = 'erp/esb-book/get-visitor-type';
  public static postBookUrl = 'erp/esb-book/post-book-transaction';
  public static updateStatusUrl = 'erp/esb-book/put-update-status';
  public static getUserInfoUrl = 'erp/user-info';
}
