import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { registerLocaleData } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatNativeDateModule} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule} from '@angular/material/button';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatIconModule} from '@angular/material/icon';
import { MatInputModule} from '@angular/material/input';
import { MatMenuModule} from '@angular/material/menu';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatSelectModule} from '@angular/material/select';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule} from '@angular/material/tabs';
import { MatTableModule} from '@angular/material/table';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatTooltipModule} from '@angular/material/tooltip';

import { NgSelect2Module } from 'ng-select2';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import localeId from '@angular/common/locales/id';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ActivityComponent } from './pages/activity/activity.component';
import { AddBookingComponent } from './pages/add-booking/add-booking.component';
import { BookingConfirmationComponent } from './pages/add-booking/booking-confirmation/booking-confirmation.component';
import { BookingDetailComponent } from './pages/booking-list/booking-detail/booking-detail.component';
import { BookingListComponent } from './pages/booking-list/booking-list.component';
import { CalendarComponent } from './pages/home/calendar/calendar.component';
import { CalendarDetailComponent } from './pages/home/calendar/calendar-detail/calendar-detail.component';
import { CancelBookingComponent } from './pages/booking-list/booking-detail/cancel-booking/cancel-booking.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { FilterStatusDialogComponent } from './shared/filter-status-dialog/filter-status-dialog.component';
import { GridPagerComponent } from './shared/grid-pager/grid-pager.component';
import { GridPaginationComponent } from './shared/grid-pagination/grid-pagination.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { LeftMenuComponent } from './pages/main/left-menu/left-menu.component';
import { MainComponent } from './pages/main/main.component';
import { OverviewComponent } from './pages/home/overview/overview.component';
import { RejectBookingComponent } from './pages/booking-list/booking-detail/reject-booking/reject-booking.component';
import { StartComponent } from './pages/start/start.component';
import { TableDetailsComponent } from './shared/table-details/table-details.component';
import { TableMapComponent } from './shared/table-map/table-map.component';
import { TimelineComponent } from './pages/home/timeline/timeline.component';
import { ErrorComponent } from './pages/error/error.component';

registerLocaleData(localeId, 'id');

@NgModule({
  declarations: [
    AppComponent,
    ActivityComponent,
    AddBookingComponent,
    BookingConfirmationComponent,
    BookingDetailComponent,
    BookingListComponent,
    CalendarComponent,
    CalendarDetailComponent,
    CancelBookingComponent,
    ConfirmDialogComponent,
    HeaderComponent,
    HomeComponent,
    FilterStatusDialogComponent,
    GridPagerComponent,
    GridPaginationComponent,
    LeftMenuComponent,
    MainComponent,
    OverviewComponent,
    RejectBookingComponent,
    StartComponent,
    TableDetailsComponent,
    TableMapComponent,
    TimelineComponent,
    ErrorComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRippleModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTabsModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxMaterialTimepickerModule,
    NgSelect2Module,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot({
      format: 'DD-MM-YYYY',
    }),
    NgxSpinnerModule,
    CarouselModule,
    ScrollingModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    })
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'id-ID' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
