import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Table, TableSection } from 'src/app/models/table.model';

@Component({
  selector: 'app-table-map',
  templateUrl: './table-map.component.html',
  styleUrls: ['./table-map.component.scss']
})
export class TableMapComponent implements OnInit {
  @Input() selectedSection: TableSection;
  @Input() viewMode: boolean;
  @Output() clickTable = new EventEmitter<Table>();

  constructor() { }

  ngOnInit() {}

  getTableLocation(table: any) {
    const top = table.posY;
    const left = table.posX;

    return {
      'top.px': top,
      'left.px': left
    };
  }

  onClickTable(item: Table) {
    this.clickTable.emit(item);
  }

  disableButton(tableStatusClass: string) {
    let result = false;
    if (this.viewMode) {
      result = true;
    } else if (!this.viewMode && ['unavailable', 'book'].includes(tableStatusClass)) {
      result = true;
    }

    return result;
  }

  trackByFn(index: number, item: Table) {
    return item.tableID;
  }

}
