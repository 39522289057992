import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { BookHistory } from 'src/app/models/book-history.model';
import { Branch } from 'src/app/models/branch.model';
import { DateRangeData } from 'src/app/models/dialog.model';

import { AddBookService } from 'src/app/services/add-book.service';
import { BranchDataService } from 'src/app/services/branch-data.service';
import { UiService } from 'src/app/services/ui.service';

import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import { MatDialog } from '@angular/material/dialog';
import { CalendarDetailComponent } from './calendar-detail/calendar-detail.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  onDestroy$ = new Subject<void>();
  actions: CalendarEventAction[];
  bookHistoryList: BookHistory[];
  branchData: Branch;
  dataSource = new MatTableDataSource<BookHistory>();
  dataSourcePerDay = new MatTableDataSource<BookHistory>();
  endDate: any;
  events: CalendarEvent[] = [];
  expandFilterItems = false;
  expandMenu: boolean;
  filterBranchID: number;
  filterDate: any;
  filterItems: string;
  filterMonth: number;
  filterTimer: any;
  selectedBookData: BookHistory[];
  starDate: any;
  viewDate: Date;
  viewDayData = false;
  toggleActive: boolean = false;
  colors = {
    cancelled: {
      primary: '#5F5F5F',
      secondary: '#E5E5E5',
    },
    confirmed: {
      primary: '#11A00A',
      secondary: '#A2CA29',
    },
    new: {
      primary: '#0A51B0',
      secondary: '#0892F5',
    },
    rejected: {
      primary: '#b94a48',
      secondary: '#F0A190',
    },
    finished: {
      primary: '#2F2E87',
      secondary: '#B34AF3',
    },
  };
  months = [
    {
      'key': 0,
      'value': 'January'
    },
    {
      'key': 1,
      'value': 'February'
    },
    {
      'key': 2,
      'value': 'March'
    },
    {
      'key': 3,
      'value': 'April'
    },
    {
      'key': 4,
      'value': 'May'
    },
    {
      'key': 5,
      'value': 'June'
    },
    {
      'key': 6,
      'value': 'July'
    },
    {
      'key': 7,
      'value': 'August'
    },
    {
      'key': 8,
      'value': 'September'
    },
    {
      'key': 9,
      'value': 'October'
    },
    {
      'key': 10,
      'value': 'November'
    },
    {
      'key': 11,
      'value': 'December'
    }
  ];

  constructor(private addBookService: AddBookService,
    private branchDataService: BranchDataService,
    private dialog: MatDialog,
    private uiService: UiService) { }

  ngOnInit() {
    this.initData();

    this.uiService.expandMenu$.subscribe(
      (data: boolean) => {
        this.expandMenu = data;
      }
    );

    this.branchDataService.activeBranchData$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: Branch) => {
        if (data) {
          this.branchData = data;
          this.initFilter();
          this.fetchBookingData();
        }
      }
    );

    this.addBookService.bookHistory$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: BookHistory[]) => {
        if (data) {
          this.bookHistoryList = data;
          if (this.bookHistoryList) {
            this.dataSource = new MatTableDataSource<BookHistory>(this.bookHistoryList);

            if (this.filterItems) {
              this.dataSource.filter = this.filterItems.trim().toLowerCase();
            }
            // @Notes: Change default filter to only 1 fields below
            this.dataSource.filterPredicate = function (f, filterStr: string): boolean {
              const filters = filterStr.split('|');
              const filterItems = filters[0];
              return (f.bookNumDisplay.toLocaleLowerCase().includes(filterItems) ||
                f.customerName.toLocaleLowerCase().includes(filterItems) ||
                f.phoneNumber.toLocaleLowerCase().includes(filterItems));
            };
            this.runFilter();
          }
        }
      }
    );
  }

  initData() {
    const d = new Date();
    const m = d.getMonth();
    const y = d.getFullYear();

    this.filterMonth = m;
    this.starDate = new Date(y,this.filterMonth,1);
    this.endDate = new Date(y,this.filterMonth+1,0);

    this.initFilterDate();

    this.actions = [
      {
        label: '<i class="fas fa-fw fa-pencil-alt"></i>',
        a11yLabel: 'Edit',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          
        },
      },
      {
        label: '<i class="fas fa-fw fa-trash-alt"></i>',
        a11yLabel: 'Delete',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.events = this.events.filter((iEvent) => iEvent !== event);
        },
      },
    ];
  }

  initFilter() {
    this.filterBranchID = this.branchData.branchID;
    this.filterItems = '';
  }

  fetchBookingData() {
    const filterDate: DateRangeData = this.filterDate;
    const startDate = moment(filterDate.startDate).format("YYYY-MM-DD");
    const endDate = moment(filterDate.endDate).format("YYYY-MM-DD");
    this.addBookService.fetchBookHistory(this.branchData.branchID, startDate, endDate).subscribe(
      (data: BookHistory[]) => {
        this.bookHistoryList = data;
        this.initEvents();
      }
    );
  }

  initFilterDate() {
    this.viewDate = this.starDate;

    this.filterDate = {
      startDate: this.starDate,
      endDate: this.endDate
    };
  }

  initEvents() {
    let items = [];
    this.dataSource.filteredData.forEach(
      (data: BookHistory) => {
        let bookDate = data.bookDate + ' ' + data.bookTime;
        let color = data.status.statusName;
        let customerName = data.bookNum ? data.customerName : '+' + data.countTotal.toString();
        let item = {
          start: new Date(bookDate),
          end: new Date(bookDate),
          title: customerName,
          color: color,
          allDay: true,
          resizable: {
          beforeStart: true,
          afterEnd: true,
          },
          draggable: false
        };

        items.push(item);
      }
    );

    this.events = items;
  }

  getStatusClass(status: string) {
    if (status == 'Confirmed') {
      return this.colors.confirmed;
    } else if (status == 'Finished') {
      return this.colors.finished;
    } else if (status == 'New') {
      return this.colors.new;
    } else if (status == 'Cancelled') {
      return this.colors.cancelled;
    } else if (status == 'Rejected') {
      return this.colors.rejected;
    }

    return null
  }

  getEventColor(status: string) {
    if (status == 'Confirmed') {
      return '-webkit-linear-gradient(#11A00A, #A2CA29)';
    } else if (status == 'Finished') {
      return '-webkit-linear-gradient(#2F2E87, #B34AF3)';
    } else if (status == 'New') {
      return '-webkit-linear-gradient(#0A51B0, #0892F5)';
    } else if (status == 'Cancelled') {
      return '-webkit-linear-gradient(#5F5F5F, #5F5F5F)';
    } else if (status == 'Rejected') {
      return '-webkit-linear-gradient(#F00000, #D02886)';
    }

    return null
  }

  getEventTitle(title: string) {
    return title.charAt(0);
  }

  onClickFilterItems() {
    this.onResetFilter();
    this.expandFilterItems = ! this.expandFilterItems;
  }

  onChangeFilter() {
    clearTimeout(this.filterTimer);
    this.filterTimer = setTimeout(() => {
      this.runFilter();
    }, 300);
  }

  onChangeMonth(e: number) {
    const d = new Date();
    const y = d.getFullYear();

    this.filterMonth = e;
    this.starDate = new Date(y,this.filterMonth,1);
    this.endDate = new Date(y,this.filterMonth+1,0);

    this.initFilterDate();
    this.fetchBookingData();
  }

  onClickDay(day: any) {
    this.viewDayData = true;
    const selectedDate = moment(day.date).format("YYYY-MM-DD");
    this.filterDate = {
      startDate: selectedDate,
      endDate: selectedDate
    };

    this.addBookService.fetchBookHistory(this.branchData.branchID, this.filterDate.startDate, this.filterDate.endDate, true).subscribe(
      (bookData: BookHistory[]) => {
        if (bookData) {
          this.dataSourcePerDay = new MatTableDataSource<BookHistory>(bookData);
          if (this.filterItems) {
            this.dataSourcePerDay.filter = this.filterItems.trim().toLowerCase();
          }
          // @Notes: Change default filter to only 1 fields below
          this.dataSourcePerDay.filterPredicate = function (f, filterStr: string): boolean {
            const filters = filterStr.split('|');
            const filterItems = filters[0];
            return (f.bookNumDisplay.toLocaleLowerCase().includes(filterItems) ||
              f.customerName.toLocaleLowerCase().includes(filterItems) ||
              f.phoneNumber.toLocaleLowerCase().includes(filterItems));
          };
          this.runFilter(true);

          if (this.dataSourcePerDay.filteredData.length > 0) {
            this.dialog.open(CalendarDetailComponent, {
              data: {
                bookHistory: this.dataSourcePerDay.filteredData,
                selectedDate
              },
              autoFocus: false,
              panelClass: ['half-screen-dialog', 'animate__animated', 'animate__slideInRight'],
            });
          }
        }
      }
    );
  }

  onResetFilter() {
    this.initFilter();
    this.onChangeFilter();
  }

  onOpenFilterItems() {
    if (!this.expandFilterItems) {
      this.expandFilterItems = true;
    }
  }

  runFilter(perDay: boolean = false) {
    let arrayValues = [];
    arrayValues.push(this.filterItems.trim().toLowerCase());
    if (!perDay) {
      this.dataSource.filter = arrayValues.join('|');
    } else {
      this.dataSourcePerDay.filter = arrayValues.join('|');
    }
    this.initEvents();
  }

}
