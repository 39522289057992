import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BookTable } from 'src/app/models/add-book.model';
import { BookHistory } from 'src/app/models/book-history.model';
import { Branch } from 'src/app/models/branch.model';
import { DateRangeData } from 'src/app/models/dialog.model';
import { AddBookService } from 'src/app/services/add-book.service';
import { BranchDataService } from 'src/app/services/branch-data.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  @ViewChild(DaterangepickerDirective, { static: false }) datePicker: DaterangepickerDirective;
  onDestroy$ = new Subject<void>();
  bookHistoryList: BookHistory[];
  branchData: Branch;
  branchDataList: Branch[];
  dataSource = new MatTableDataSource<BookHistory>();
  date: string;
  day: string;
  expandMenu: boolean;
  expandFilterItems = false;
  filterBranchID: number;
  filterDate: any;
  filterItems: string;
  filterTimer: any;
  tableDataArray: any;
  timeTableDataArray: any;
  timeDataArray: any;
  tableBookedTotal: number;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };

  constructor(private addBookService: AddBookService,
    private branchDataService: BranchDataService,
    private uiService: UiService) { }

  ngOnInit() {
    this.uiService.expandMenu$.subscribe(
      (data: boolean) => {
        this.expandMenu = data;
      }
    );

    this.branchDataService.activeBranchData$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: Branch) => {
        if (data) {
          this.branchData = data;
          this.initFilter();
          this.initData();
          this.fetchBookingData();
        }
      }
    );

    this.addBookService.bookHistory$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: BookHistory[]) => {
        if (data) {
          this.bookHistoryList = data;
          if (this.bookHistoryList) {
            this.dataSource = new MatTableDataSource<BookHistory>(this.bookHistoryList);

            if (this.filterItems) {
              this.dataSource.filter = this.filterItems.trim().toLowerCase();
            }

            // @Notes: Change default filter to only 2 fields below
            this.dataSource.filterPredicate = function (f, filterStr: string): boolean {
              const filters = filterStr.split('|');
              const filterItems = filters[0];
              return (f.bookNumDisplay.toLocaleLowerCase().includes(filterItems) ||
                f.customerName.toLocaleLowerCase().includes(filterItems) ||
                f.phoneNumber.toLocaleLowerCase().includes(filterItems));
            };
            this.runFilter();
          }
        }
      }
    );
  }

  initData() {
    this.date = formatDate(new Date(), 'd MMM yyyy', 'en-US');
    this.day = formatDate(new Date(), 'EEEE', 'en-US');
    this.branchDataList = this.branchDataService.branchData$.value;

    //Init timer array list data
    var arr = [], i, j;
    for(i=0; i<24; i++) {
      for(j=0; j<2; j++) {
        let prefix = i < 10 ? "0" + i : i;
        arr.push(prefix + ":" + (j===0 ? "00" : 30*j) );
      }
    }

    var pos = arr.indexOf("00:00");
    this.timeDataArray = arr.slice(pos).concat(arr.slice(0,pos));
  }

  initFilter() {
    this.filterDate = {
      startDate: moment(),
      endDate: moment()
    };

    this.filterBranchID = this.branchData.branchID;
    this.filterItems = '';
  }

  fetchBookingData() {
    this.tableBookedTotal = 0;
    const filterDate: DateRangeData = this.filterDate;
    const startDate = moment(filterDate.startDate).format("YYYY-MM-DD");
    const endDate = moment(filterDate.endDate).format("YYYY-MM-DD");
    this.addBookService.fetchBookHistory(this.filterBranchID, startDate, endDate).subscribe(
      (data: BookHistory[]) => {    
        this.bookHistoryList = data;
        this.tableBookedTotal = this.getTableBooked();
      }
    );
  }

  getStatusClass(status: string) {
    let className = '';
    if (status == 'Confirmed') {
      className = 'status-confirmed';
    } else if (status == 'Finished') {
      className = 'status-finished';
    } else if (status == 'New') {
      className = 'status-new';
    } else if (status == 'Cancelled') {
      className = 'status-cancelled';
    } else if (status == 'Rejected') {
      className = 'status-rejected';
    }

    return className;
  }

  getStatusIcon(status: string) {
    let icon = '';
    if (status == 'Confirmed') {
      icon = 'assets/images/icon-check-green.png';
    } else if (status == 'Finished') {
      icon = 'assets/images/icon-check-purple.png';
    } else if (status == 'New') {
      icon = 'assets/images/icon-check-blue.png';
    } else if (status == 'Cancelled') {
      icon = 'assets/images/icon-check-grey.png';
    } else if (status == 'Rejected') {
      icon = 'assets/images/icon-check-red.png';
    }

    return icon;
  }

  getStatusClassButton(status: string) {
    let className = '';
    if (status == 'Confirmed') {
      className = 'div-status-confirmed';
    } else if (status == 'Finished') {
      className = 'div-status-finished';
    } else if (status == 'New') {
      className = 'div-status-new';
    } else if (status == 'Cancelled') {
      className = 'div-status-cancelled';
    } else if (status == 'Rejected') {
      className = 'div-status-rejected';
    }

    return className;
  }

  onClickFilterItems() {
    this.onResetFilter();
    this.expandFilterItems = ! this.expandFilterItems;
  }

  openDatePicker(e: any) {
    this.datePicker.open(e);
  }

  onChangeDate() {
    const diff = this.filterDate.endDate.diff(this.filterDate.startDate, 'days') + 1;
    if (diff > 31) {
      this.uiService.showAlert('Warning', 'Maximum booking list history is 31 days')
    } else {
      this.fetchBookingData();
    }
  }

  onChangeFilter() {
    clearTimeout(this.filterTimer);
    this.filterTimer = setTimeout(() => {
      this.runFilter();
    }, 300);
  }

  onResetFilter() {
    this.initFilter();
    this.onChangeFilter();
  }

  onOpenFilterItems() {
    if (!this.expandFilterItems) {
      this.expandFilterItems = true;
    }
  }

  runFilter() {
    let arrayValues = [];
    arrayValues.push(this.filterItems.trim().toLowerCase());
    this.dataSource.filter = arrayValues.join('|');
    this.tableDataArray = this.addBookService.getTableListAsArray(this.dataSource.filteredData, 'table');
    this.timeTableDataArray = this.addBookService.getTableListAsArray(this.dataSource.filteredData, 'timetable');
    setTimeout(() => {
      this.initTimeTableContent();
    }, 0);
  }

  getTableBooked() {
    let result = 0;
    if (this.bookHistoryList) {
      this.bookHistoryList.forEach(
        (data: BookHistory) => {
          if (data.bookTable.length > 0) {
            data.bookTable.forEach(
              (val: BookTable) => {
                if (val.tableID) {
                  result += 1;
                }
              }
            );
          }
        }
      );
    }

    return result;
  }

  setTimeTable(bookID: any, cellID: any, bookTime: string, bookEndTime: string, index: number = -1) {
    let bookTimeArr = bookTime.split(':');
    let bookTimeHour = +bookTimeArr[0] * 60;
    let bookTimeMinute = +bookTimeArr[1] * 1;
    let bookTimeTotalInMinutes = +bookTimeHour + +bookTimeMinute;

    let bookEndTimeArr = bookEndTime.split(':');
    let bookEndTimeHour = +bookEndTimeArr[0] * 60;
    let bookEndTimeMinute = +bookEndTimeArr[1] * 1;
    let bookEndTimeTotalInMinutes = +bookEndTimeHour + +bookEndTimeMinute;

    let bookTimeGap = bookEndTimeTotalInMinutes - bookTimeTotalInMinutes;
    let length = bookTimeGap/30;

    const element = document.getElementById(cellID);
    if (element) {
      const leftPosition = element.offsetLeft + 32.5;
      const topPosition = element.offsetTop + 33.5;
      document.getElementById(bookID).style.left = leftPosition + 'px';
      document.getElementById(bookID).style.top = topPosition + 'px';
      document.getElementById(bookID).style.width = 75 * length + 'px';
      if (index == 0) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  initTimeTableContent() {
    let i = 0;
    this.timeTableDataArray.forEach(table => {
      const bookID = 'id'+table.tableName+'-'+table.bookTime;
      const cellID = table.tableName+'-'+table.bookTime;
      this.setTimeTable(bookID, cellID, table.bookTime, table.bookEndTime, i);
      i += 1;
    });
  }

}
