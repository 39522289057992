import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogDataFilterStatus } from 'src/app/models/dialog.model';

@Component({
  selector: 'app-filter-status-dialog',
  templateUrl: './filter-status-dialog.component.html',
  styleUrls: ['./filter-status-dialog.component.scss']
})
export class FilterStatusDialogComponent implements OnInit {
  selectedStatus: string[];

  constructor(public dialogRef: MatDialogRef <FilterStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataFilterStatus) { }

  ngOnInit() {
    this.selectedStatus = [];
    this.selectedStatus = this.data.status;
  }

  onClickItem(status: string) {
    let newStatus = [];
    if (status == 'All') {
      if (this.selectedStatus.includes(status)) {
        newStatus = this.selectedStatus.filter(
          (data) => {
            return data != status;
          }
        );
      } else {
        newStatus.push(status);
      }
    } else {
      newStatus = this.selectedStatus.filter(
        (data) => {
          return data != 'All';
        }
      );

      if (newStatus.includes(status)) {
        newStatus = this.selectedStatus.filter(
          (data) => {
            return data != status;
          }
        );

        if (newStatus.length == 0) {
          newStatus.push('All');
        }
      } else {
        newStatus.push(status);
        if (newStatus.length > 4) {
          newStatus = this.selectedStatus.filter(
            (data) => {
              return data == 'All';
            }
          );
          newStatus.push('All');
        }
      }
    }

    this.selectedStatus = newStatus;
  }

  getClass(status: string) {
    return this.selectedStatus.includes(status) ? 'active-item' : '';
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSelectStatus() {
    this.dialogRef.close(this.selectedStatus);
  }

}
