import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private companyAuthKey: string;

  constructor(private httpClient: HttpClient) { }

  /**
   * Set web referrer / baseUrl to local storage
   */
  setReferrer(baseUrl: string) {
    localStorage.setItem('referrer', baseUrl);
  }

  /**
   * Get web referrer / baseUrl to local storage
   */
  getReferrer() {
    return localStorage.getItem('referrer') ? localStorage.getItem('referrer') : '';
  }

  /**
   * Set user token to local storage
   */
  setUserToken(userAuthKey: string) {
    localStorage.setItem('session', userAuthKey);
  }

  /**
   * Get user token from local storage
   */
  getUserToken() {
    return localStorage.getItem('session') ? localStorage.getItem('session') : '';
  }

  /**
   * Get company token based on user session
   */
  getCompanyToken() {
    return this.privatePost<string>(AppSettings.getCompanyAuthUrl).pipe(
      tap(t => { this.companyAuthKey = t; })
    );
  }

  /**
   * Make a post request with bearer authentication.
   * @param url string
   * @param body any
   */
  privatePost<T>(url: string, body?: {}) {
    return this.httpClient.post<T>(`${this.getReferrer()}/${url}`, body, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this.getUserToken()}`)
    });
  }

  /**
   * Make a post request with bearer authentication.
   * @param url string
   * @param body any
   */
  privateCompanyPost<T>(url: string, body?: {}) {
    return this.httpClient.post<T>(`${this.getReferrer()}/${url}`, body, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this.companyAuthKey}`)
    });
  }
}
