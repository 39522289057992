import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

import { GridBaseComponent } from '../grid-base/grid-base.component';

@Component({
  selector: 'app-grid-pagination',
  templateUrl: './grid-pagination.component.html',
  styleUrls: ['./grid-pagination.component.scss']
})
export class GridPaginationComponent extends GridBaseComponent implements OnInit, OnChanges {
  @Input() items: any[];
  @Input() itemValue: string;
  @Input() itemText: string;
  @Input() itemText2: string;
  @Input() itemColumn: number;
  @Input() itemRow: number;
  @Input() bold = true;
  @Input() isClassic: boolean;
  @Input() selectedItem: any;
  @Output() clickItem = new EventEmitter();
  showItems: any[];
  btnContainerStyle: any;
  btnStyle: any;
  arrayObjectSelectedItems: any[];
  arrayStringSelectedItems: string[];

  constructor() {
    super();
  }

  ngOnInit() {
    this.initStyle();
  }

  initStyle() {
    if (this.isClassic) {
      this.btnContainerStyle = {
        'height': `60px`,
        'max-height': `calc((100% / ${this.itemRow}))`
      };
    } else {
      this.btnContainerStyle = {
        'height': `calc((100% / ${this.itemRow}) - 10px)`,
        'max-height': `calc((100% / ${this.itemRow}) - 10px)`
      };
    }
  }

  ngOnChanges() {
    if (this.isClassic) {
      this.initStyle();
    }

    if (this.selectedItem) {
      if (!Array.isArray(this.selectedItem)) {
        this.arrayObjectSelectedItems = [this.selectedItem];
      } else {
        this.arrayObjectSelectedItems = _.cloneDeep(this.selectedItem);
      }
    }

    this.arrayStringSelectedItems = [];
    if (this.arrayObjectSelectedItems) {
      this.arrayObjectSelectedItems.forEach(
        (item: any) => {
          this.arrayStringSelectedItems.push(`${item[this.itemValue]}${item[this.itemText]}`);
        }
      );
    }

    this.filterItem();
  }

  filterItem() {
    if (this.items) {
      this.maxPage = Math.ceil(this.items.length / (this.itemColumn * this.itemRow));

      this.showItems = this.items.slice(this.pageNumber * (this.itemColumn * this.itemRow),
        (this.pageNumber + 1) * (this.itemColumn * this.itemRow));
    }
    if (this.showItems && (this.showItems.length === 0)) {
      this.goFirstPage();
    }
  }

  onClickItem(item: any) {
    this.clickItem.emit(item);
    this.selectedItem = item;
  }

}
