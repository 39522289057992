import { formatDate } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';

import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BookHistory, BookHistoryStatusLog } from 'src/app/models/book-history.model';
import { Branch } from 'src/app/models/branch.model';
import { DateRangeData, DialogDataFilterStatus } from 'src/app/models/dialog.model';

import { AddBookService } from 'src/app/services/add-book.service';
import { BranchDataService } from 'src/app/services/branch-data.service';
import { UiService } from 'src/app/services/ui.service';

import { FilterStatusDialogComponent } from 'src/app/shared/filter-status-dialog/filter-status-dialog.component';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(DaterangepickerDirective, { static: false }) datePicker: DaterangepickerDirective;
  @ViewChild('txtKeyword') txtKeyword: ElementRef;
  onDestroy$ = new Subject<void>();
  bookHistoryList: BookHistory[];
  bookHistoryStatusLog: BookHistoryStatusLog[];
  bookTables: string[];
  branchData: Branch;
  branchDataList: Branch[];
  dataSource = new MatTableDataSource<BookHistory>();
  date: string;
  day: string;
  expandFilterItems = false;
  expandMenu: boolean;
  filterBranchID: number;
  filterDate: any;
  filterItems: string;
  filterStatus: string[];
  filterTimer: any;
  pageSizeOptions: number[];
  searchInput: string;
  selectedBookNum: string;
  selectedBranchDataList: Branch[];
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };

  constructor(private addBookService: AddBookService,
    private branchDataService: BranchDataService,
    public dialog: MatDialog,
    private uiService: UiService) { }

  ngOnInit() {
    this.uiService.expandMenu$.subscribe(
      (data: boolean) => {
        this.expandMenu = data;
      }
    );

    this.branchDataService.activeBranchData$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: Branch) => {
        if (data) {
          this.branchData = data;
          this.initFilter();
          this.initData();
          this.fetchBookingData();
        }
      }
    );

    this.addBookService.bookHistory$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: BookHistory[]) => {
        if (data) {
          this.selectedBookNum = '';
          this.bookHistoryStatusLog = [];
          this.bookHistoryList = data;

          if (this.bookHistoryList) {
            this.dataSource = new MatTableDataSource<BookHistory>(this.bookHistoryList);
            this.dataSource.paginator = this.paginator;

            if (this.filterItems) {
              this.paginator.pageIndex = 0;
              this.dataSource.filter = this.filterItems.trim().toLowerCase();
            }
            // @Notes: Change default filter to only 2 fields below
            this.dataSource.filterPredicate = function (f, filterStr: string): boolean {
              const filters = filterStr.split('|');
              const filterItems = filters[0];
              const filterStatus1 = filters[1];
              if (filterStatus1 == 'All') {
                return (f.bookNumDisplay.toLocaleLowerCase().includes(filterItems) ||
                f.customerName.toLocaleLowerCase().includes(filterItems) ||
                f.phoneNumber.toLocaleLowerCase().includes(filterItems));
              } else {
                const allFilterStatus = filters.filter(
                  (dataVal: string) => {
                    return dataVal == f.status.statusName;
                  }
                );

                return (f.bookNumDisplay.toLocaleLowerCase().includes(filterItems) ||
                f.customerName.toLocaleLowerCase().includes(filterItems) ||
                f.phoneNumber.toLocaleLowerCase().includes(filterItems)) && (allFilterStatus.length > 0 ? true : false);
              }
            };
            this.runFilter(true);
          }
        }
      }
    );

    this.addBookService.bookHistoryStatusLog$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (data: BookHistoryStatusLog[]) => {
        if (data) {
          this.bookHistoryStatusLog = data;
        }
      }
    );
  }

  initData() {
    this.date = formatDate(new Date(), 'd MMM yyyy', 'en-US');
    this.day = formatDate(new Date(), 'EEEE', 'en-US');
    this.branchDataList = this.branchDataService.branchData$.value;
    this.selectedBranchDataList = this.branchDataList;
    this.pageSizeOptions = [15];
  }

  initFilter() {
    this.filterDate = {
      startDate: moment(moment().format('YYYY-MM-01')),
      endDate: moment()
    };

    this.filterBranchID = this.branchData.branchID;
    this.filterItems = '';
    this.filterStatus = ['All'];
  }

  fetchBookingData() {
    const filterDate: DateRangeData = this.filterDate;
    const startDate = moment(filterDate.startDate).format("YYYY-MM-DD");
    const endDate = moment(filterDate.endDate).format("YYYY-MM-DD");
    this.addBookService.fetchBookHistory(this.filterBranchID, startDate, endDate).subscribe();
  }

  fetchBookingLog(bookNum: string) {
    this.addBookService.fetchBookHistoryStatusLog(bookNum).subscribe();
  }

  getStatusNameValue(status: string) {
    let result = status;
    if (result == 'New') {
      result = 'New Book';
    }
    return result;
  }

  getStatusClass(status: string) {
    let className = '';
    if (status == 'Confirmed') {
      className = 'status-confirmed';
    } else if (status == 'Finished') {
      className = 'status-finished';
    } else if (status == 'New') {
      className = 'status-new';
    } else if (status == 'Cancelled') {
      className = 'status-cancelled';
    } else if (status == 'Rejected') {
      className = 'status-rejected';
    }

    return className;
  }
  
  getStatusIcon(status: string) {
    let icon = '';
    if (status == 'Confirmed') {
      icon = 'assets/images/icon-check-green.png';
    } else if (status == 'Finished') {
      icon = 'assets/images/icon-check-purple.png';
    } else if (status == 'New') {
      icon = 'assets/images/icon-check-blue.png';
    } else if (status == 'Cancelled') {
      icon = 'assets/images/icon-check-grey.png';
    } else if (status == 'Rejected') {
      icon = 'assets/images/icon-check-red.png';
    }

    return icon;
  }
  
  getImgLog(status: string) {
    let image = '';
    if (status == 'Confirmed' || status == 'Processed') {
      image = 'assets/images/icon-book-confirmed.png';
    } else if (status == 'New') {
      image = 'assets/images/icon-book-new.png';
    } else if (status == 'Cancel POS Table' || status == 'Cancelled') {
      image = 'assets/images/icon-book-cancelled.png';
    } else if (status == 'Rejected') {
      image = 'assets/images/icon-book-rejected.png';
    } else if (status == 'Change Book') {
      image = 'assets/images/icon-book-change.png';
    } else if (status == 'Open POS Table') {
      image = 'assets/images/icon-book-processed.png';
    } else if (status == 'Finish POS Table') {
      image = 'assets/images/icon-book-finished.png';
    }

    return image;
  }
  
  getIconBtn(dataLength: number) {
    let image = '';
    if (dataLength > 0) {
      image = 'assets/images/icon-show-book.png';
    } else if (dataLength == 0) {
      image = 'assets/images/icon-show-book-disable.png';
    }

    return image;
  }

  getTableValue(table: string[]) {
    this.bookTables = [];
    table.forEach(
      (item: any) => {
        this.bookTables.push(item.tableName);
      }
    );
    return this.bookTables.toString();
  }

  getTooltip(log: BookHistoryStatusLog) {
    if (log.status == 'Cancelled') {
      return log.reason;
    }
    return null;
  }

  onClickBranch(branch: Branch) {
    this.branchDataService.setActiveBranch(branch);
    this.branchDataService.fetchSalesModeData(branch.branchID).subscribe();
  }

  onOpenFilterItems() {
    if (!this.expandFilterItems) {
      this.expandFilterItems = true;
    }
  }

  onClickFilterItems() {
    this.onResetFilter();
    this.expandFilterItems = ! this.expandFilterItems;
  }

  onClickFilterStatus() {
    const data: DialogDataFilterStatus = {
      status: this.filterStatus
    };
    const dialogRef = this.dialog.open(FilterStatusDialogComponent, {
      data,
      autoFocus: false,
      id: 'FilterStatusDialogComponent',
      panelClass: 'dialog-filter-status'
    });

    dialogRef.afterClosed().subscribe(
      (status: string[]) => {
        if (status) {
          this.filterStatus = status;
          this.onChangeFilter();
        }
      }
    );
  }

  onChangeFilter() {
    clearTimeout(this.filterTimer);
    this.filterTimer = setTimeout(() => {
      this.runFilter(true);
    }, 300);
  }

  onChangeDate() {
    const diff = this.filterDate.endDate.diff(this.filterDate.startDate, 'days') + 1;
    if (diff > 31) {
      this.uiService.showAlert('Warning', 'Maximum booking list history is 31 days')
    } else {
      this.fetchBookingData();
    }
  }

  onClickBook(bookNum: string) {
    this.selectedBookNum = bookNum;
    this.fetchBookingLog(this.selectedBookNum);
  }

  openDatePicker(e: any) {
    this.datePicker.open(e);
  }

  onResetFilter() {
    this.initFilter();
    this.onChangeFilter();
  }

  onSearchBranch(value: string) {
    if (value) {
      this.selectedBranchDataList = this.search(value);
    } else {
      this.selectedBranchDataList = this.branchDataList;
    }
  }

  onResetFilterInput() {
    this.searchInput = '';
    this.selectedBranchDataList = this.branchDataList;
  }

  search(value: string) { 
    let filter = value.toLowerCase();
    return this.branchDataList.filter(option => option.branchName.toLowerCase().includes(filter));
  }

  runFilter(resetPagination: boolean) {
    if (resetPagination && this.paginator) {
      this.paginator.pageIndex = 0;
    }

    let arrayValues = [];
    arrayValues.push(this.filterItems.trim().toLowerCase());
    if (this.filterStatus.length > 0) {
      this.filterStatus.forEach(
        (data: string) => {
          arrayValues.push(data);
        }
      );
    }
    this.dataSource.filter = arrayValues.join('|');
    
    this.selectedBookNum = this.dataSource.filteredData.length > 0 ? this.dataSource.filteredData[0].bookNum : '';
    if (this.selectedBookNum) {
      this.fetchBookingLog(this.selectedBookNum);
    } else {
      this.bookHistoryStatusLog = [];
    }
  }

  getBookNumDisplay() {
    if (this.bookHistoryStatusLog && this.bookHistoryStatusLog.length > 0) {
      return this.bookHistoryStatusLog[0].bookNumDisplay;
    }
    return '';
  }

  onBlur() {
    this.txtKeyword.nativeElement.focus();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
