import { formatDate } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { BookHistory } from 'src/app/models/book-history.model';
import { DialogDataBookHistory } from 'src/app/models/dialog.model';

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss']
})
export class BookingConfirmationComponent implements OnInit {
  bookHistory: BookHistory;
  createdDate: string;
  bookDate: string;
  bookTime: string;
  bookTables: string[];
  tables: string;

  constructor(public dialogRef: MatDialogRef <BookingConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataBookHistory,
    private router: Router) { }

  ngOnInit() {
    this.bookHistory = this.data.bookHistory;
    this.createdDate = formatDate(this.bookHistory.createdDate, 'd MMM yyyy', 'en-US');
    this.bookDate = formatDate(this.bookHistory.bookDate, 'd MMM yyyy', 'en-US');
    this.bookTime = this.bookHistory.bookTime;

    this.bookTables = [];
    if (this.bookHistory.bookTable) {
      this.bookHistory.bookTable.forEach(
        (item: any) => {
          this.bookTables.push(item.tableName);
        }
      );

      this.tables = this.bookTables.toString();
    }
  }

  onFinish() {
    this.dialogRef.close();
    this.router.navigate(['/book/booking-list']);
  }

}
