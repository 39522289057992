<div class="d-flex flex-column h-100">
    <div class="d-flex flex-row dialog-header">
        <div class="d-flex flex-row flex-grow-1">
            <div style="padding: 0 10px 0 0;">
                <img src="assets/images/icon-calendar.png" style="width: 50px;" />
            </div>
            <div class="d-flex flex-column">
                <div class="selected-date" style="padding-left: 10px;">
                    {{ selectedDate }}
                </div>
                <div class="d-flex flex-row" style="padding-left: 10px;">
                    <span class="total-book">{{ totalBooking }}</span>
                    <span class="total-book-text">Books</span>
                </div>
            </div>
        </div>
        <div>
            <mat-icon matRipple class="icon-clear" (click)="onClose()">clear</mat-icon>
        </div>
    </div>

    <hr style="margin: 0 25px 10px 25px">

    <div class="d-flex flex-column flex-grow-1" style="max-height: 75vh; overflow-y: scroll;">
        <div *ngFor="let bookData of (dataSource.filteredData | slice: lowValue : highValue); let even = even; let odd = odd" 
            class="d-flex flex-column row-list"
            [ngClass]="{ even: even, odd: odd }">
            <div class="d-flex flex-row w-100">
                <div class="d-flex flex-column item-list customer-name">
                    <span class="item-label">
                        Name
                    </span>
                    <span class="item-value">
                        {{ bookData.customerName }}
                    </span>
                </div>
                <div class="d-flex flex-column item-list book-time">
                    <span class="item-label">
                        Time
                    </span>
                    <span class="item-value">
                        {{ bookData.bookTime }}
                    </span>
                </div>
                <div class="d-flex flex-column item-list book-number">
                    <span class="item-label">
                        Booking Code
                    </span>
                    <span class="item-value">
                        {{ bookData.bookNumDisplay }}
                    </span>
                </div>
            </div>
            <div class="d-flex flex-row w-100 mt-2">
                <div class="d-flex flex-column item-list phone-number">
                    <span class="item-label">
                        Phone Number
                    </span>
                    <span class="item-value">
                        {{ bookData.phoneNumber }}
                    </span>
                </div>
                <div class="d-flex flex-column item-list branch">
                    <span class="item-label">
                        Branch
                    </span>
                    <span class="item-value">
                        {{ bookData.branch.branchName }}
                    </span>
                </div>
                <div class="d-flex flex-column item-list table-pax">
                    <span class="item-label">
                        Table/Pax
                    </span>
                    <span class="item-value">
                        <span>
                            {{ bookData.bookTable.length > 0 ? getTableValue(bookData.bookTable) : '-' }} /
                        </span>
                        <span style="margin-left: 1px;">
                            {{ bookData.paxTotal }} pax
                        </span>
                    </span>
                </div>
            </div>
            <div class="d-flex flex-row section-status mt-2">
                <div class="d-flex flex-grow-1 item-label align-items-center">
                    Booking Status :
                </div>
                <div class="d-flex flex-row book-status"
                [ngClass]="{ even: odd, odd: even }">
                    <span [ngClass]="getStatusClass(bookData.status.statusName)" 
                        style="margin-right: 10px;">{{ getStatusNameValue(bookData.status.statusName) }}
                    </span>
                    <img [src]="getStatusIcon(bookData.status.statusName)" width="14" height="14" />
                </div>
            </div>
        </div>
    </div>

    <mat-dialog-actions align="end" class="mat-footer">
        <mat-paginator *ngIf="bookHistoryList?.length > 0" [length]="dataSource.filteredData.length" pageSize=4
            (page)="getPaginatorData($event)"
            [pageSizeOptions]="pageSizeOptions"
            showFirstLastButtons>
        </mat-paginator>
    </mat-dialog-actions>
</div>