import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Branch } from 'src/app/models/branch.model';
import { BranchDataService } from 'src/app/services/branch-data.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  branchData: Branch;
  expandMenu = true;
  fetchBranchData$: Observable<Branch[]>;
  isLoading: boolean;

  constructor(private branchDataService: BranchDataService,
    private router: Router,
    private uiService: UiService) { }

  ngOnInit() {
    this.uiService.expandMenu$.subscribe(
      (data: boolean) => {
        this.expandMenu = data;
      }
    );

    this.uiService.isLoading$.subscribe(
      (data: boolean) => {
        this.isLoading = data;
      }
    );

    this.expandMenu = this.expandMenu ? this.expandMenu : true;
    this.uiService.expandMenu$.next(this.expandMenu);

    this.branchDataService.fetchBookChannelData().subscribe();

    this.fetchBranchData$ = this.branchDataService.fetchBranchData().pipe(
      tap((branchData) => {
        if (!isEmpty(branchData)) {
          const branchString = this.branchDataService.loadLocalStorage('branch');
          let activeBranch: Branch = null;
          if (branchString) {
            let branchJson = JSON.parse(branchString);
            activeBranch = branchData.find(bd => bd.branchID == branchJson['branchID']) ? branchData.find(bd => bd.branchID == branchJson['branchID']) : branchData[0];
          } else {
            activeBranch = branchData[0];
          }
          this.branchDataService.activeBranchData$.next(activeBranch);
        } else {
          this.router.navigate(['error']);
        }
      }),
      catchError((error) => {
        console.log(error);
        this.router.navigate(['error']);
        return of(null);
      })
    );
  }

}
