<div class="d-flex flex-column calendar-page">
    <div class="d-flex flex-row panel-header justify-content-center"
        [ngClass]="!expandMenu ? 'expand':''">
        <div class="d-flex flex-row align-items-center">
            <div class="d-flex flex-row div-status align-items-center mr-3">
                <div class="div-status-new circle-status"></div>
                <span class="text-status">New Book</span>
            </div>

            <div class="d-flex flex-row div-status align-items-center mr-3">
                <div class="div-status-confirmed circle-status"></div>
                <span class="text-status">Confirmed</span>
            </div>

            <div class="d-flex flex-row div-status align-items-center mr-3">
                <div class="div-status-cancelled circle-status"></div>
                <span class="text-status">Cancelled</span>
            </div>

            <div class="d-flex flex-row div-status align-items-center mr-3">
                <div class="div-status-rejected circle-status"></div>
                <span class="text-status">Rejected</span>
            </div>

            <div class="d-flex flex-row div-status align-items-center">
                <div class="div-status-finished circle-status"></div>
                <span class="text-status">Finished</span>
            </div>
        </div>

        <div class="d-flex flex-grow-1"></div>

        <div class="mr-3" style="position: relative;">
            <input 
                [(ngModel)]="filterItems"
                (ngModelChange)="onChangeFilter()"
                type="text"
                class="filter-items"
                [ngClass]="{'expand': expandFilterItems, 'expand-items': expandFilterItems}"
                [readOnly]="!expandFilterItems"
                (click)="onOpenFilterItems()"
                placeholder="Search: Name, Booking Code, Phone" />

            <img src="assets/images/icon-search.png" 
                class="icon-search pointer" width="14" height="14"
                [ngClass]="expandFilterItems ? 'expand':'no-expand'"
                (click)="onClickFilterItems()" />

            <mat-icon matRipple class="icon-clear" 
                [ngClass]="expandFilterItems ? 'expand':'no-expand'"
                (click)="onClickFilterItems()">clear</mat-icon>
        </div>

        <div class="d-flex flex-row align-items-center filter-month">
            <i class="fa fa-calendar mr-3"></i>
            <mat-select [(ngModel)]="filterMonth"
                (selectionChange)="onChangeMonth($event.value)">
                <mat-option *ngFor="let month of months"
                    [value]="month.key">
                    {{ month.value }}
                </mat-option>
            </mat-select>
        </div>
    </div>

    <div class="d-flex flex-column calendar-content">
        <ng-template #customCellTemplate
            let-day="day"
            let-locale="locale"
            let-tooltipPlacement="tooltipPlacement"
            let-highlightDay="highlightDay"
            let-unhighlightDay="unhighlightDay"
            let-eventClicked="eventClicked"
            let-tooltipTemplate="tooltipTemplate"
            let-tooltipAppendToBody="tooltipAppendToBody"
            let-tooltipDelay="tooltipDelay">
            <div class="cal-cell-top">
                <span class="cal-day-number">
                  {{ day.date | calendarDate:'monthViewDayNumber':locale }}
                </span>
                <img *ngIf="day.isToday" class="icon-today" 
                    src="assets/images/today.gif" width="60" height="15" />
            </div>
            <div *ngIf="day.badgeTotal > 0">
                <span class="cal-day-badge">
                    {{ day.badgeTotal }}
                </span>
            </div>
            <div *ngIf="day.badgeTotal > 0 && day.badgeTotal > 1" style="margin-bottom: 10px;">
                <span style="margin-left: 12px; font-size: 1rem; font-weight: lighter; color: #5F5F5F;">Books</span>
            </div>
            <div *ngIf="day.badgeTotal > 0  && day.badgeTotal < 2" style="margin-bottom: 10px;">
                <span style="margin-left: 12px; font-size: 1rem; font-weight: lighter; color: #5F5F5F;">Book</span>
            </div>
            <div *ngIf="day.events.length > 0"
                class="cal-events">
                <div *ngFor="let event of (day.events | slice: 0 : 5); index as i"
                    class="cal-event"
                    [ngStyle]="{ 'background': i < 4 ? getEventColor(event.color) : '#D33D3D' }"
                    [ngClass]="event?.cssClass"
                    (mouseenter)="highlightDay.emit({ event: event })"
                    (mouseleave)="unhighlightDay.emit({ event: event })"
                    [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip':event"
                    [tooltipPlacement]="tooltipPlacement"
                    [tooltipEvent]="event"
                    [tooltipTemplate]="tooltipTemplate"
                    [tooltipAppendToBody]="tooltipAppendToBody"
                    [tooltipDelay]="tooltipDelay">
                    <span *ngIf="i < 4">{{ getEventTitle(event.title) }}</span>
                    <span *ngIf="i == 4 && day.badgeTotal < 6">{{ getEventTitle(event.title) }}</span>
                    <span *ngIf="i > 3 && day.badgeTotal > 5">+{{ day.badgeTotal-i }}</span>
                </div>
            </div>
        </ng-template>

        <mwl-calendar-month-view
            [viewDate]="viewDate"
            [events]="events"
            [cellTemplate]="customCellTemplate"
            (dayClicked)="onClickDay($event.day)">
        </mwl-calendar-month-view>
    </div>
</div>