<div class="d-flex flex-column panel-table-details">
    <div class="dialog-header">
        <div class="d-flex flex-row align-items-center justify-content-center p-3">
            <div class="d-flex flex-row align-items-center flex-grow-1">
                <img src="assets/images/icon-table-detail-white.png" 
                    style="width: 30px; height: 15px; margin-right: 15px;" />
                <span class="d-flex flex-grow-1">Table Details</span>
            </div>
            <div class="d-flex icon-clear" (click)="onClose()">
                <mat-icon matRipple>clear</mat-icon>
            </div>
        </div>
    </div>

    <div class="table-wrapper">
        <table class="time-table">
            <thead class="table-head-details">
                <tr class="row-head">
                    <th>
                        <span class="booking-summary">Table Number</span>
                    </th>
                    <th class="table-head-time" *ngFor="let time of timeDataArray; let i=index;">
                        {{ time }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let table of tableDataArray; let i=index;"
                    class="table-list-section">
                    <th class="table-detail">
                        <span class="table-name">{{ table.tableName }}</span><br>
                        <span class="table-pax">Total Pax: {{ table.paxTotal }}</span><br>
                        <span class="table-amount">Min amount: {{ table.minAmount }}</span>
                    </th>
                    <td *ngFor="let time of timeDataArray; let j=index;"
                        [id]="table.tableName+'-'+time"
                        style="width: 75px; height: 75px; min-width: 75px; min-height: 75px;"></td>
                </tr>
            </tbody>
        </table>
        <button *ngFor="let table of timeTableDataArray; let i=index;"
            [id]="'id'+table.tableName+'-'+table.bookTime"
            class="timetable-button"
            [ngClass]="getStatusClassButton(table.status)">
            <img class="icon-time-content" src="assets/images/icon-timeline-green.png" width="14" height="14" />
            <span class="table-book-time">{{ table.bookTime }} - {{ table.bookEndTime }}</span>
            <div class="d-flex flex-column tooltip-time">
                <div class="d-flex flex-column tooltip-status">
                    <span class="status-label">Status:</span>
                    <div class="d-flex flex-row align-items-center">
                        <span class="status-content"
                            [ngClass]="getStatusClass(table.status)">{{ table.status }}</span>
                        <img [src]="getStatusIcon(table.status)" width="12" height="12" />
                    </div>
                </div>
                <div class="d-flex flex-column tooltip-status">
                    <span class="status-label">Branch:</span>
                    <span class="branch-content">{{ table.branch }}</span>
                </div>
                <div class="d-flex flex-column tooltip-status">
                    <span class="status-label">Customer Name:</span>
                    <span class="branch-content">{{ table.customer }}</span>
                </div>
            </div>
        </button>
    </div>
</div>