<mat-toolbar class="main-header" style="padding: 0px;">
  <img src="assets/images/icon-top-right-corner.png" class="corner-right-item" />
  <div *ngIf="!expandMenu" class="d-flex justify-content-center align-items-center" 
    style="width: 0; margin-left: 40px; margin-bottom: auto; margin-top: 20px;">
    <button mat-button
      class="h-100"
      style="outline: none !important; padding: 0 !important"
      (click)="onClickMenu()">
      <img src="assets/images/icon-burger-black.svg" class="image-responsive center-block" />
    </button>
  </div>
  <div class="d-flex flex-grow-1 h-100" style="margin-left: 2rem; margin-right: 2rem; padding-top: 18px;">
    <div class="d-flex flex-grow-1 flex-column" style="line-height: initial;">
      <span class="brand-name">{{ branchData.brand.brandName }}</span>
      <div class="d-flex flex-row align-items-center" style="padding-top: 8px;">
        <img src="assets/images/icon-account-white.png" 
          class="image-responsive center-block mr-2" style="width: 14px;" />
        <span class="user-name">{{ userData.fullName }}</span>
      </div>
    </div>
    <div class="d-flex flex-row" [ngClass]="getClassTime()">
      <div class="div-header-time">
        <img class="header-ripple" src="assets/images/header-ripple.gif" />
        <span style="font-size: .9rem; margin-left: 5px;">{{ date }}</span>
        <span style="font-size: .9rem; font-weight: 700; margin-left: 5px;">{{ time }}</span>
      </div>
    </div>
  </div>
</mat-toolbar>
