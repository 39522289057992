<div *ngIf="data.type === 'confirm-add' || data.type === 'new-add' || data.type === 'confirm-reject' || data.type === 'confirm-cancel'" 
  class="d-flex flex-column dialog-body">
  <div *ngIf="data.type === 'confirm-add'"
    class="dialog-header justify-content-center"
    [ngStyle]="{'background-image' : 'url(assets/images/img-confetti.png)'}">
    <img class="imgA1" src="assets/images/icon-integrate.gif" />
  </div>
  <div *ngIf="data.type === 'new-add'"
    class="dialog-header justify-content-center">
    <img class="imgA1-new" src="assets/images/img-binocular.png" />
  </div>
  <div class="d-flex flex-column modal-body justify-content-center align-items-center">
    <div class="dialog-title">
      {{ data.title }}
    </div>
    <div class="dialog-message">
      {{ data.message }}
    </div>
  </div>
</div>
<div *ngIf="data.type === 'alert'" 
  class="d-flex flex-column dialog-body">
  <div class="d-flex flex-column modal-body justify-content-center align-items-center">
    <div class="dialog-message">
      {{ data.message }}
    </div>
  </div>
</div>
<div *ngIf="data.type === 'alert'"
  class="modal-footer justify-content-center">
  <button mat-raised-button
    class="btn-confirm"
    style="width: 100px;"
    mat-dialog-close>
    Ok
  </button>
</div>
<div *ngIf="data.type === 'confirm-add'"
  class="d-flex align-items-center justify-content-center" 
  style="padding: 1rem;">
  <button mat-flat-button
    class="btn-cancel w-100"
    mat-dialog-close>
    Cancel
  </button>
  <button mat-flat-button
    class="btn-confirm w-100"
    [mat-dialog-close]="true">
    Add & Confirm
  </button>
</div>

<div *ngIf="data.type === 'new-add'"
  class="d-flex align-items-center justify-content-center" 
  style="padding: 1rem;">
  <button mat-flat-button
    class="btn-cancel w-100"
    mat-dialog-close>
    Cancel
  </button>
  <button mat-flat-button
    class="btn-confirm w-100"
    [mat-dialog-close]="true">
    Submit Booking
  </button>
</div>

<div *ngIf="data.type === 'confirm-reject'"
  class="d-flex align-items-center justify-content-center" 
  style="padding: 1rem;">
  <button mat-flat-button
    class="btn-cancel w-100"
    mat-dialog-close>
    Close
  </button>
  <button mat-flat-button
    class="btn-reject w-100"
    [mat-dialog-close]="true">
    Reject Booking
  </button>
</div>

<div *ngIf="data.type === 'confirm-cancel'"
  class="d-flex align-items-center justify-content-center" 
  style="padding: 1rem;">
  <button mat-flat-button
    class="btn-cancel w-100"
    mat-dialog-close>
    Close
  </button>
  <button mat-flat-button
    class="btn-reject w-100"
    [mat-dialog-close]="true">
    Cancel Booking
  </button>
</div>