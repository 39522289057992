import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivityComponent } from './pages/activity/activity.component';
import { AddBookingComponent } from './pages/add-booking/add-booking.component';
import { BookingDetailComponent } from './pages/booking-list/booking-detail/booking-detail.component';
import { BookingListComponent } from './pages/booking-list/booking-list.component';
import { CalendarComponent } from './pages/home/calendar/calendar.component';
import { HomeComponent } from './pages/home/home.component';
import { OverviewComponent } from './pages/home/overview/overview.component';
import { TimelineComponent } from './pages/home/timeline/timeline.component';
import { MainComponent } from './pages/main/main.component';
import { StartComponent } from './pages/start/start.component';
import { ErrorComponent } from './pages/error/error.component';

const routes: Routes = [
  { path: '', redirectTo: 'start', pathMatch: 'full' },
  { path: 'start', component: StartComponent },
  { path: 'book', component: MainComponent, children: [
    { path: '', component: HomeComponent },
    { path: 'activity', component: ActivityComponent },
    { path: 'booking-list', component: BookingListComponent },
    { path: 'booking-list/add-booking', component: AddBookingComponent },
    { path: 'booking-list/booking-detail/:booknumdisplay', component: BookingDetailComponent },
    { path: 'booking-list/edit-booking/:booknumdisplay', component: AddBookingComponent },
    { path: 'dashboard', component: HomeComponent, children: [
      { path: '', component: OverviewComponent },
      { path: 'overview', component: OverviewComponent },
      { path: 'calendar', component: CalendarComponent },
      { path: 'timeline', component: TimelineComponent },
    ] },
  ] },
  { path: 'error', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
